/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import DefaultProfile from "../../assets/img/dummyimage.png";
import DefaultGameImage from "../../assets/img/DefaultProduct.jpg";
import CashPaymentIcon from "../../assets/img/cash-payment.png";
import CloseIcon from '../../assets/img/closebutton.png';
import CustomMendotoryMsg from "../Common/CustomMendotoryMsg";
import CountrySelectInput from "../Common/CountrySelectInput/CountryMobileInput";
import SucessIcon from "../../assets/img/success.svg";
import CloseBtn from "../../assets/img/x-mark (2).png";
import CommonModal from "../Common/CommonModal/index";
import RedeemCreditsModal from "../Common/RedeemCreditsModal";
import { useSelector, useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { accountNavigate, updateUser, ClearReduxFlow, getProductList, GetProductsIdWise, updateProfilePic, getOverCallingGeoLocation, GetOrderList, getCreditlist, getRegioLcTime, getUser, updateUserWallet, CallLogoutUser, IsSiteIsBlockCheck, getGeoCoplyMessage, getGeoCoplyLocation, getSpoofingDetection } from "../../redux/actions";
import { PROFILE_UPDATE_API_URL, PROFILE_UPLOAD_API_URL, GET_PRODUCT_API_URL, ORDER_LIST_API_URL, USER_CREDIT_LIST_API_URL, GET_PROFILE_API_URL, USER_REDEEM_COUPON_API_URL } from "../Shared/constant";
import ProfileLoader from "../../assets/img/spinner_transferent.svg";
import { timeSince } from "../Common/Timesince";
import PaginationPage from "../Common/PaginationPage";
//Calling WOWjs
import WOW from 'wowjs';
import { useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";


const Account = () => {
    const dispatch = useDispatch();
    const LocationUrl = window.location.href.split("/")[3];
    const { productidObjects, accessToken, orderlist, profiledata, accountUrl,creditList, products, geoComplyLocation, UniqueBrowserId } = useSelector((state) => state.allReducers);
    const [ CustomError, setCustomError ] = useState(false);
    const [ fname, setFname ] = useState((profiledata?.first_name === null || profiledata?.first_name === "") ? "" : profiledata?.first_name);
    const [ lname, setLname ] = useState((profiledata?.last_name === null || profiledata?.last_name === "") ? "" : profiledata?.last_name);
    const [ profileUpload, setProfileUpload ] = useState(profiledata?.user_avatar_path === null ? "" : profiledata?.user_avatar_path);
    const [ MobileNo, setMobileNo ] = useState({
        countrycode: (profiledata?.phone === null || profiledata?.phone === "") ? "1" : (parseInt(profiledata?.phone?.split("-")[0]) || parseInt(profiledata?.phone?.split(" ")[0])),
        number: (profiledata?.phone === null || profiledata?.phone === "") ? "" : (parseInt(profiledata?.phone?.split("-")[1]) || parseInt(profiledata?.phone?.split(" ")[1])),
    });
    const [ commonPopup, setCommonPopup ] = useState({
        open: false,
        title: "",
        description: "",
        buttontitle: "" 
    });
    const [ fileUploadLoader, setFileUploadLoader ] = useState(false);
    const [ RedeemCrditModalState, setRedeemCrditModalState ] = useState({
        open: false,
        title: "",
        RedeemData: {}
    });
    const navigate = useNavigate();
    const [ sweepsCode, setSweepsCode ] = useState("");
    // error sweeps code
    const [ errorSweepsCode, setErrorSweepsCode ] = useState("");
    const [ MobileError, setMobileError ] = useState("");
    const [ RequestProcessModel, setRequestProcessModel ] = useState({
        open: false,
        data: {}
    });
    const [paginationLength, setPaginationLength] = useState({});
    const [paginationLengthNew, setPaginationLengthnew] = useState({});
    const [maintain,setMaintain] = useState({
        filter:"all",
        limit: 10,
        page: 1,
        search:""
    });
    const [ userType, setUserType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ userSearch, setUserSearch ] = useState("");
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ searchNone, setSearchNone ] = useState(false);

    const RevordImg = require('../../assets/img/gift.png')

    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init();
    }, []);

    // code updated
    useEffect(() => {
        setFname((profiledata?.first_name === null || profiledata?.first_name === "") ? "" : profiledata?.first_name);
        setLname((profiledata?.last_name === null || profiledata?.last_name === "") ? "" : profiledata?.last_name);
        setProfileUpload(profiledata?.user_avatar_path === null ? "" : profiledata?.user_avatar_path);
        setMobileNo({
            ...MobileNo, 
            countrycode: (profiledata?.phone === null || profiledata?.phone === "") ? "1" : (parseInt(profiledata?.phone?.split("-")[0]) || parseInt(profiledata?.phone?.split(" ")[0])),
            number: (profiledata?.phone === null || profiledata?.phone === "") ? "" : (parseInt(profiledata?.phone?.split("-")[1] || parseInt(profiledata?.phone?.split(" ")[1]))),    
        });
    }, [ profiledata ]);


    useEffect(() => {
        UserGetAPI_Function();
    }, [LocationUrl, accountUrl]);

    const UserGetAPI_Function = () => {
        window.axios.get(GET_PROFILE_API_URL, {
            headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        }}).then(function (result) {
            if(result?.status === 200) {
                dispatch(getUser(result?.data?.data));
            };
        }).catch(function (result) {
            if(result?.response?.status === 403) {
                dispatch(CallLogoutUser());
                localStorage.removeItem("access_tokens");
            }
        });
    };

    // add credit modal
    const addredeemCredit = (data) => {
        setRedeemCrditModalState({
            ...RedeemCrditModalState,
            open: !RedeemCrditModalState.open,
            title: `Claim credits for ${data?.name}`,
            RedeemData: data,
        });
    };

    // Discard Account Modal Function
    const DiscardAccount = () => {
        setCommonPopup({...commonPopup, 
            open: !commonPopup?.open, 
            title: "Discard Changes?", 
            description: "Are you sure you want to discard the changes you've made?", 
            buttontitle: "Discard"
        })
    };

    // Discard field modal function
    const DiscardAccountData = () => {
        setCommonPopup({...commonPopup, open: false});
        setFname((profiledata?.first_name === null || profiledata?.first_name === "") ? "" : profiledata?.first_name);
        setLname((profiledata?.last_name === null || profiledata?.last_name === "") ? "" : profiledata?.last_name);
        setMobileNo({...MobileNo, 
            countrycode: (profiledata?.phone === null || profiledata?.phone === "") ? "1" : (parseInt(profiledata?.phone?.split("-")[0]) || parseInt(profiledata?.phone?.split(" ")[0])),
            number: (profiledata?.phone === null || profiledata?.phone === "") ? "" : (parseInt(profiledata?.phone?.split("-")[1] || parseInt(profiledata?.phone?.split(" ")[1])))
        })
    };

    // profile update function
    const ProfileUpdate = () => {
        document.getElementById("accountflag")?.classList?.remove("active");
        if(fname !== "" && lname !== "" && MobileNo?.number?.toString()?.length === 10) {
            const jsonData = JSON.stringify({ first_name: fname, last_name: lname, phone: MobileNo?.countrycode + " " + MobileNo?.number, location: geoComplyLocation});
            window?.axios?.post(PROFILE_UPDATE_API_URL, jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                }
            }).then(function (result) {
                dispatch(updateUser(result?.data?.data));
                setMobileError("");
                toast.success(result.data.msg);
            })  
            .catch(function (result) {
                toast.error(result.response.data.error);
                if(result?.response?.status === 403) {
                    dispatch(CallLogoutUser());
                    localStorage.removeItem("access_tokens");
                };
            });
        } else {
            setCustomError(true);
            if(MobileNo?.number === "") {
                setMobileError("Phone Number cannot be empty");
            } else if(MobileNo?.number?.toString()?.length) {
                setMobileError("Please enter 10 digit number");
            } else {
                setMobileError("");
            }
        }
    }

    const clearInput = () => {
        setSweepsCode("");
      };
    // upload avatar image
    const UploadAvatarImage = (e) => {
        setFileUploadLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("avatar", files, files.name);

        window.axios.post( PROFILE_UPLOAD_API_URL, formData, {
            headers: {
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            if (result.data) {
                setProfileUpload(result.data.data.user_avatar_path);
                dispatch(updateProfilePic(result.data.data.user_avatar_path));
                setTimeout(() => setFileUploadLoader(false), 100);
            }
        }).catch(function (result) {
            setTimeout(() => setFileUploadLoader(false), 100);
        });
    };

    // on enter submit redeem code function
    const onSubmitLogin = (e) => {
        if(e.keyCode === 13) {
            submitCode();
        };
    };

    const submitCode = () => {
        if(sweepsCode !== "") {
            dispatch(getRegioLcTime(""));
            dispatch(getOverCallingGeoLocation(true));
            GetGeoLicenceFunction("user-redeem"); 
            document.getElementById("pageisLoading").style.display = "flex";
        } else {
            setErrorSweepsCode("Sweeps entry code cannot be empty");
        }
    }

    const GetGeoLicenceFunction = async (url) => {
        window.axios.get(`${process.env.REACT_APP_BASE_URL}user/geocomply/license/${UniqueBrowserId}`, {
        headers: {
            'Content-Type': 'application/json',
        }}).then(function (result) {
            if(result?.status === 200) {
            GetGeoLocate(result?.data?.data?.license, url);
            dispatch(getGeoCoplyMessage(""));
            };
        }).catch(function (result) {
            dispatch(getGeoCoplyMessage(""));
        });
    };
    
    // Geo location api
    const GetGeoLocate = (jsonData, url) => {
        var geoClient = window.GcHtml5.createClient();
        geoClient.setUserId(UniqueBrowserId);
        geoClient.setReason(url);
        geoClient.setLicense(jsonData);
        geoClient.events.on('hint', function(reason, data) {
        }).on('engine.success', function(text, xml) {
            LocationPermission(text);
            dispatch(getGeoCoplyLocation(text));
            // localStorage.setItem("GeoLocationToken", text);
        }).on('*.failed', function(code, message) {
            if (this.event === 'revise.failed' || this.event === 'config.failed' || this.event === 'engine.failed') {
                if (code === geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION) {
                    //network issue, we can add retry logic if needed
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_INVALID_FORMAT || code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED) {
                    //e.g. update license and retry geolocating
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS) {
                    //it's safe to be ignored, or we can wait for previous request to complete and try again
                } else {
                    //something went wrong with GeoComply service, your license or your custom data
                    //e.g. submit log to record the issue
                }
            } else if (this.event === 'browser.failed') {
                //it's safe to be ignored, you can remove this block or add your own logic
            }
        });
        geoClient.request();
    };
    
    // location Permissions api
    const LocationPermission = async (text) => {
        console.log("Redeem Permission");
        const jsonData = JSON.stringify({ 
        "id": UniqueBrowserId,
        "data": text
        });
        window.axios.post(`${process.env.REACT_APP_BASE_URL}user/geocomply/permission`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (result) {
           
            if(result?.status === 200) {
            
            dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
            if(result?.data?.data?.status === true) {
                // document.getElementById("pageisLoading").style.display = "none";
                // success code
                // callGeoTimeApi(result?.data?.data?.re_geolocation);
                dispatch(getOverCallingGeoLocation(false));
                submitRedeemGeoFunction();
                dispatch(getRegioLcTime(result?.data?.data?.re_geolocation));
                localStorage.removeItem("starttime");
                localStorage.removeItem("targettime");
            }
            dispatch(getSpoofingDetection(result?.data?.data?.spoofing_detection));
            if(result?.data?.data?.status === false) {
                document.getElementById("pageisLoading").style.display = "none";
                dispatch(getGeoCoplyMessage(result?.data?.data?.troubleshooter));
                if(result?.data?.data?.spoofing_detection != null){
                    // add spoofing detection popup
                    console.log("spoofing detection");
                }
            }
            } else if(result?.status === 400) {
                dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
            };
        })  
        .catch(function (result) { 
        document.getElementById("pageisLoading").style.display = "none";
        if(result?.response?.data?.error === "Geo-comply failed.") {
            localStorage.setItem("UniquID", "id" + Math.random().toString(16).slice(2));
        } else if(result?.status === 400) {
            dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
        };
        });
    };

    // submit redeem code
    const submitRedeemGeoFunction = () => {
        if(sweepsCode !== "") {
            window.axios.get(`${USER_REDEEM_COUPON_API_URL}${sweepsCode}` , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                }
            }).then(function (result) {
                if(result?.data) {
                    document.getElementById("pageisLoading").style.display = "none";
                    dispatch(updateUserWallet(result.data.data));
                    setSweepsCode("");
                    setErrorSweepsCode("");
                    toast.success(result.data.msg);
                }
            }).catch(function (result) {
                document.getElementById("pageisLoading").style.display = "none";
                // toast.error(result.response.data.error);
                if(result?.response?.status === 403) {
                    dispatch(CallLogoutUser());
                    localStorage.removeItem("access_tokens");
                };
                if(result?.response?.status === 401){
                    // 401 yellow 
                    // toast.info(result.response.data.error)
                    toast.warning(result.response.data.error)
                }else{
                    toast.error(result.response.data.error);
                }

            });
        } else {
            setErrorSweepsCode("Sweeps entry code cannot be empty");
        }
    };

    // responsive tab change
    const selctResponsiveTab = (value) => {
        if(value === "My Books") {
            dispatch(accountNavigate("my-books"));
        } else if (value === "My Account") {
            dispatch(accountNavigate("my-account"));
        } else if (value === "Game Request") {
            dispatch(accountNavigate("game-request"));
        } else if(value === "Refer & Earn"){
            dispatch(accountNavigate("refer-earn"));
        }
        else {
            dispatch(accountNavigate("order-list"))
        };
    };

    useEffect(() => {
        getGamecreditList();
        getOrderList();
        setPaginationLength({});
        setPaginationLengthnew({});
        ProductList();
    }, [ maintain.filter,maintain.limit, maintain.page, searchNone, accountUrl ]);

    useEffect(() => {
        getOrderList();
    }, [ userType, userLimit, currentPage, searchNone ]);

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        setMaintain({...maintain,
            filter:"all",
            limit: 10,
            page: 1,
            search:""
        });
        setUserSearch("");
        setCurrentPage(1);
    }, [ accountUrl ]);

    // on Enter search
    const onEnterSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code !== 17 && code !== 18) {
            if(code === 13) {
                getGamecreditList(1);
            } else if(maintain.search === "" || maintain.search?.length <= 1) {
                getGamecreditList();
            };
        };
    };

    // on Enter search
    const onEnterSearchNew = (e) => {
        const code = e.which || e.keyCode;
        if(code !== 17 && code !== 18) {
            if(code === 13) {
                getOrderList(1);
            } else if(maintain.search === "" || maintain.search?.length <= 1) {
                getOrderList();
            };
        };
    };

    const clearSearch = () => {
        setMaintain({
             ...maintain,
             search:""
        });
        setUserSearch("");
        setSearchNone(true);
    };

    const getOrderList = (pages) => {
        if(pages !== undefined) {
            setCurrentPage(pages);
        };
        
        window.axios.get(`${ORDER_LIST_API_URL}/${userType}/${userLimit}/${pages === undefined ?currentPage : pages}/${userSearch === "" ? "none" : userSearch.trim()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            dispatch(GetOrderList(result.data.data));
            setPaginationLengthnew(result.data.pagination);
            setSearchNone(false);
        })
        .catch(function (result) {
                dispatch(GetOrderList([]));
             if(result?.response?.status === 403) {
               dispatch(ClearReduxFlow());
               navigate(LOGIN_URL);
               localStorage.removeItem("access_tokens");
             }
        });
    };

    const getGamecreditList = (pages) => {
        if(pages !== undefined) {
            setMaintain({...maintain,
                page: pages,
            });
        };
        window.axios.get(`${USER_CREDIT_LIST_API_URL}/${maintain?.filter}/${maintain.limit}/${pages === undefined ? maintain.page : pages}/${maintain.search === "" ? "none" : maintain.search.trim()}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            setSearchNone(false);
            dispatch(getCreditlist(result.data.data));
            setPaginationLength(result.data.pagination)
        })
        .catch(function (result) {
                dispatch(getCreditlist([]));
             if(result?.response?.status === 403) {
               dispatch(ClearReduxFlow());
               navigate(LOGIN_URL);
               localStorage.removeItem("access_tokens");
             }
        });
    };

    const ProductList = () => {
        //  get my product
        window.axios.get( `${GET_PRODUCT_API_URL}/5000/1`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (result) {
            dispatch(GetProductsIdWise(result.data.data));
            dispatch(getProductList(result.data.data));
        }).catch(function (result) {
            dispatch(GetProductsIdWise([]));
            dispatch(getProductList([]));
            if(result?.response?.status === 403) {
                dispatch(CallLogoutUser());
                localStorage.removeItem("access_tokens");
            };
        });
    };

    // page change
    const currentFunction = (page) => {
        setMaintain({...maintain,
            page: page,
        });
    };

    const currentFunctionSet = (page) => {
        setCurrentPage(page);
    };

// console.log("profiledata",profiledata?.referral_code)
    const referralCode = "ABCDG123";
const [cvalue,setCValue] = useState("Copy")
    const copyReferralCode = () => {
        navigator.clipboard.writeText(profiledata?.referral_code);
        setCValue("Copied")
        setTimeout(() => {
            setCValue("Copy")
        }, 2000);

    };

    return(<section className="accoutntwrapper">
        <div className="container custom_container_account">
            <div className="myacountsatabswrps">
                <div className="row" style={{ margin: "auto" }}>
                    <div className="col-md-3">
                        <div className="myaccountprofilepic">
                            <div className="acctprofile">
                                <img src={profileUpload} alt="profile" onError={(e)=> {e.target.src = DefaultProfile}} />
                                <label className="uploadfileovarlay">
                                    <div className="svgwrp">
                                        <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24"><path fillRule="evenodd" d="M14.267,4 C14.801,4 15.299,4.287 15.566,4.75 L15.566,4.75 L16.144,5.75 C16.233,5.904 16.399,6 16.577,6 L16.577,6 L19.5,6 C20.327,6 21,6.673 21,7.5 L21,7.5 L21,17.5 C21,18.327 20.327,19 19.5,19 L19.5,19 L4.5,19 C3.673,19 3,18.327 3,17.5 L3,17.5 L3,7.5 C3,6.673 3.673,6 4.5,6 L4.5,6 L7.435,6 C7.609,6 7.773,5.907 7.863,5.758 L7.863,5.758 L8.483,4.727 C8.752,4.278 9.245,4 9.769,4 L9.769,4 Z M14.267,5 L9.769,5 C9.594,5 9.43,5.093 9.34,5.242 L9.34,5.242 L8.72,6.273 C8.451,6.722 7.958,7 7.435,7 L7.435,7 L4.5,7 C4.224,7 4,7.225 4,7.5 L4,7.5 L4,17.5 C4,17.775 4.224,18 4.5,18 L4.5,18 L19.5,18 C19.776,18 20,17.775 20,17.5 L20,17.5 L20,7.5 C20,7.225 19.776,7 19.5,7 L19.5,7 L16.577,7 C16.043,7 15.545,6.713 15.278,6.25 L15.278,6.25 L14.7,5.25 C14.611,5.096 14.445,5 14.267,5 L14.267,5 Z M11.9996,7.9999 C13.2656,7.9999 14.4706,8.6099 15.2236,9.6329 C15.9876,10.6719 16.1996,11.9939 15.8046,13.2609 C15.4326,14.4579 14.4576,15.4329 13.2606,15.8049 C12.8426,15.9349 12.4176,15.9989 11.9996,15.9989 C11.1516,15.9989 10.3286,15.7349 9.6336,15.2229 C8.6106,14.4699 7.9996,13.2659 7.9996,11.9999 C7.9996,9.7939 9.7946,7.9999 11.9996,7.9999 Z M11.9996,8.9999 C10.3456,8.9999 8.9996,10.3459 8.9996,11.9999 C8.9996,12.9489 9.4586,13.8529 10.2256,14.4169 C11.0056,14.9919 12.0026,15.1479 12.9636,14.8499 C13.8506,14.5729 14.5736,13.8519 14.8496,12.9639 C15.1486,12.0029 14.9916,11.0059 14.4176,10.2259 C13.8526,9.4579 12.9496,8.9999 11.9996,8.9999 Z"></path></svg>
                                    </div>
                                    <input type="file" onChange={UploadAvatarImage} accept=".svg, .png, .jpg, .jpeg, .webp" />
                                </label>
                                {fileUploadLoader === true && <div className="imageloader"><img src={ProfileLoader} alt="loader"/></div>}
                            </div>
                            <h4>{(profiledata.first_name === null && profiledata.last_name === null) ? profiledata?.email : 
                                (profiledata.first_name !== null ? profiledata.first_name : "") + " " + (profiledata.last_name !== null ? profiledata.last_name : "")}</h4>
                        </div>
                        {screen.width > 767 ? (<div className="accountsRouting">
                            <a className={accountUrl === "my-books" ? "accountsRoutingLink active" : "accountsRoutingLink"} onClick={() => dispatch(accountNavigate("my-books"))}>Member Dashboard</a>
                            <a className={accountUrl === "my-account" ? "accountsRoutingLink active" : "accountsRoutingLink"} onClick={() => dispatch(accountNavigate("my-account"))}>My Account</a>
                            <a className={accountUrl === "game-request" ? "accountsRoutingLink active" : "accountsRoutingLink"} onClick={() => dispatch(accountNavigate("game-request"))}>Game Requests</a>
                            <a className={accountUrl === "order-list" ? "accountsRoutingLink active" : "accountsRoutingLink"} onClick={() => dispatch(accountNavigate("order-list"))}>Order List</a>
                            <a className={accountUrl === "refer-earn" ? "accountsRoutingLink active" : "accountsRoutingLink"} onClick={() => dispatch(accountNavigate("refer-earn"))}>Refer & Earn</a>
                        </div>) : (<select className="selectAccountssct" onChange={(e) => selctResponsiveTab(e.target.value)}>
                            <option value="My Books">Member Dashboard</option>
                            <option value="My Account">My Account</option>
                            <option value="Game Request">Game Requests</option>
                            <option value="Order List">Order List</option>
                            <option value="Refer & Earn">Refer & Earn</option>
                        </select>)}
                    </div>  
                    <div className="col-md-9">
                        {accountUrl === "my-account" ? (<div className="myaccountwrps">
                            <div className="myaccountwrpsheader">
                                <div className="headertitle">
                                    <h4>My Account</h4>
                                    <p>View and edit your personal info below.</p>
                                </div>
                                <div className="bttngroup">
                                    <Button className="btn discardbtn" onClick={() => DiscardAccount()}>Discard</Button>
                                    <Button className="btn updteinfobtn" onClick={() => ProfileUpdate()}>Update Info</Button>
                                </div>
                            </div>
                            <div className="accountwrps">
                                <h4>Account</h4>
                                <h5>Update your personal information.</h5>
                                <div className="paymentformsWraps">
                                    <div className="rowcustom rowcustom-col-2">
                                        <div className={CustomError && fname === "" ? "form-group error" : "form-group"}>
                                            <label>Login Email:</label>
                                            <div className="emailset">{profiledata?.email}</div>
                                            <div className="noteemail">Your Login email can't be changed</div>
                                        </div>
                                    </div>
                                    <div className="rowcustom rowcustom-col-2">
                                        <div className={CustomError && fname === "" ? "form-group error" : "form-group"}>
                                            <label>First Name</label>
                                            <div className="form-groupfiled">
                                                <input type="text" className="form-control" value={fname} onChange={(e) => setFname(e.target.value.trim())} placeholder="Enter First Name" />
                                                {fname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                                {CustomError && fname === "" && <CustomMendotoryMsg value={fname} label={"First name"} />}
                                            </div>
                                        </div>
                                        <div className={CustomError && lname === "" ? "form-group error" : "form-group"}>
                                            <label>Last Name</label>
                                            <div className="form-groupfiled">
                                                <input type="text" className="form-control" value={lname} onChange={(e) => setLname(e.target.value.trim())} placeholder="Enter Last Name" />
                                                {lname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                                {CustomError && lname === "" && <CustomMendotoryMsg value={lname} label={"Last name"} />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rowcustom rowcustom-col-2">
                                        <div className={(MobileError !== "" && MobileNo?.number?.toString()?.length !== 10) ? "form-group error" : "form-group"}>
                                            <label>Phone</label>
                                            <div className="form-groupfiled">
                                                <CountrySelectInput 
                                                    MobileNo={MobileNo}
                                                    setMobileNo={setMobileNo}
                                                    id={"accountflag"}
                                                />
                                                {MobileNo?.number?.toString()?.length === 10 && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                                {MobileNo?.number?.toString()?.length !== 10 && <div className="danger-color">{MobileError}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rowcustomright" >
                                        <Button className="btn discardbtn" onClick={() => DiscardAccount()}>Discard</Button>
                                        <Button className="btn updteinfobtn" onClick={() => ProfileUpdate()}>Update Info</Button>
                                    </div>
                                </div>
                            </div>
                        </div>) : accountUrl === "game-request" ? (<div className="gameListing">
                            <h4>Game Request List :</h4>
                            <div className="card-header-New">
                                <div className="searchBoxwithbtn" style={{ width: "324px" }}>
                                    {maintain.search !== "" && (<label id="focusLabel" className="focusLabel">Search after hitting the enter button.</label>)}
                                    <input type="text" className="form-control" value={maintain.search} onKeyDown={(e) => onEnterSearch(e)} onChange={(e) => setMaintain({...maintain,search:e.target.value})} placeholder="Search (Name, request id)" />
                                    {maintain.search === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                        <path d="M21 21l-6 -6"></path>
                                    </svg>) : (<img src={CloseIcon} className="icon searchclear" onClick={() => clearSearch()} alt="close" />)}
                                </div>
                                <div className="card-header-right">
                                    <select className="form-control activestatusselet" style={{ width: "125px" }} value={maintain.filter} onChange={(e) => setMaintain({...maintain, filter: e.target.value})}>
                                        <option value="all">All</option>
                                        <option value="approved">Approved</option>
                                        <option value="rejected">Rejected</option>
                                        <option value="unprocessed">Unprocessed</option>
                                    </select>
                                    <select className="form-control userLimitselect" value={maintain.limit} onChange={(e) => setMaintain({...maintain,limit:e.target.value})}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="60">60</option>
                                        <option value="70">70</option>
                                        <option value="80">80</option>
                                        <option value="90">90</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                            <div className="table_set">
                                <div className="table-responsive" style={{ width: window?.screen?.width < 700 ? window?.screen?.width - 40 + 'px' : "inherit" }}>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Request ID</th>
                                                <th>Game Name</th>
                                                <th>Image</th>
                                                <th>Credit</th>
                                                <th>Available balance</th>
                                                <th>Registered</th>
                                                <th>Created at</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {creditList && creditList?.length === 0 ? (<tr>
                                            <td colSpan="10" style={{ textAlign: "center" }}>No Record Found</td>
                                        </tr>) : creditList?.map((item, index) => {
                                                return (<tr key={index}>
                                                <td>{item?.id}</td>
                                                <td>{item?.product_name}</td>
                                                <td className="userAvatar">
                                                    {item?.product_img === null ? (<img src={DefaultGameImage} style={{ width: "70px" }} alt="game" />) : (<img src={item?.product_img} style={{ width: "70px" }} alt="game" />)}
                                                </td>
                                                <td>{item?.amount / 100}</td>
                                                <td>${item?.user_available_balance / 100}</td>
                                                <td>{(item?.is_registered === null || item?.is_registered === false) ? <span className="label_status error">No</span> : <span className="label_status success">Yes</span>}</td>
                                                <td>{timeSince(new Date(item?.created_at))}</td>
                                                <td>
                                                    {item?.status === "rejected" ? <span className="label_status error">Rejected</span> : item?.status === null ? <span className="label_status error">Unprocessed</span> : <span className="label_status success">Approved</span>}
                                                </td>
                                            </tr>)})}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            {creditList?.length > 0 && (<PaginationPage 
                                paginationLength={paginationLength}
                                totalPages={paginationLength?.total_records}
                                currentPage={maintain?.page}
                                onChangePage={currentFunction}
                                userLimit={maintain?.limit}
                            />)}
                        </div>) : accountUrl === "order-list" ? (<div className="gameListing">
                            <h4>Order List :</h4>
                            <div className="card-header-New">
                                <div className="searchBoxwithbtn" >
                                    {userSearch !== "" && (<label id="focusLabel" className="focusLabel">Search after hitting the enter button.</label>)}
                                    <input type="text" className="form-control" value={userSearch} onKeyDown={(e) => onEnterSearchNew(e)} onChange={(e) => setUserSearch(e.target.value)} placeholder="Search (First name, Last name)" />
                                    {userSearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                        <path d="M21 21l-6 -6"></path>
                                    </svg>) : (<img src={CloseIcon} className="icon searchclear" onClick={() => clearSearch()} alt="close" />)}
                                </div>
                                <div className="card-header-right">
                                    <select className="form-control activestatusselet" style={{ width: "125px" }} value={userType} onChange={(e) => SelectUserTypeFnct(e)}>
                                        <option value="all">All</option>
                                        <option value="paid">Paid</option>
                                        <option value="unpaid">Unpaid</option>
                                        <option value="pending">Pending</option>

                                    </select>
                                    <select className="form-control userLimitselect" value={userLimit} onChange={(e) => setUserLimit(e.target.value)}>
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="40">40</option>
                                        <option value="50">50</option>
                                        <option value="60">60</option>
                                        <option value="70">70</option>
                                        <option value="80">80</option>
                                        <option value="90">90</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                            <div className="table_set">
                                <div className="table-responsive" style={{ width: window?.screen?.width < 700 ? window?.screen?.width - 40 + 'px' : "inherit" }}>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Email</th>
                                                <th>Country</th>
                                                <th>Phone</th>
                                                <th style={{ width: "250px" }}>Packages</th>
                                                <th>Total Amount</th>
                                                <th>Payment Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderlist && orderlist?.length === 0 ? (<tr>
                                            <td colSpan="10" style={{ textAlign: "center" }}>No Record Found</td>
                                                </tr>) : orderlist?.map((orders, index) => {
                                                    return(<tr key={index}>
                                                        <td>{orders?.first_name}</td>
                                                        <td>{orders?.last_name}</td>
                                                        <td>{orders?.email}</td>
                                                        <td>{orders?.country?orders?.country:"-"}</td>
                                                        <td>{orders?.phone}</td>
                                                        <td>
                                                            {orders?.cart ? (
                                                                <React.Fragment>
                                                                    {orders?.cart?.map((elm, index) => {
                                                                        return(<div className="order_list" key={index}>
                                                                        {elm?.package_image_path === null ? (<img src={DefaultGameImage} style={{ width: "60px" }} alt="game" />) : (<img src={elm?.package_image_path} style={{ width: "60px" }} alt="game" />)}
                                                                        {elm?.name}
                                                                    </div>)
                                                                    })}
                                                                </React.Fragment>
                                                            ):(
                                                                <React.Fragment>
                                                                    -
                                                                </React.Fragment>
                                                            )}
                                                        </td>
                                                        <td>${orders?.total_amount / 100}</td>
                                                        <td>
                                                            {/* {orders?.is_paid === true ? <span className="label_status success">Paid</span> : <span className="label_status error">Failed</span>} */}
                                                            {orders?.is_paid === true ? (
                                    <span className="label_status success">Paid</span>
                                    ) : orders?.is_paid === false ? (
                                    <span className="label_status error">Failed</span>
                                    ) : (
                                    <span className="badge bg_faint_yellow">Pending</span>
                                    )}
                                                        </td>
                                                    </tr>)
                                                })}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            {orderlist?.length > 0 && (<PaginationPage 
                                paginationLength={paginationLengthNew}
                                totalPages={paginationLengthNew?.total_records}
                                currentPage={currentPage}
                                onChangePage={currentFunctionSet}
                                userLimit={userLimit}
                            />)}
                        </div>) :  accountUrl === "refer-earn" ? 
                        <>
                        <div className="Refer-container">
            <h3 className="title">Give more <span className="subtitle">referrals.</span> Earn more <span className="subtitle">rewards.</span></h3>

            <div className="reward-section">
                <div className="rewards-box">
                <p> Refer 1 = Earn 1 Sweep Coin</p>
                <p> Refer 5 = Earn 3 Sweeps Coins</p>
                <p> Refer 10 = Earn 7 Sweeps Coins</p>
                <p> Refer 20 = Earn 10 Sweeps Coins</p>

                </div>

            </div>
            <div className="description">
            Invite your friends and family to Sweepcoins. Earn on their 1st package purchase.
            </div>
            <div  className="Button_Conatiner">

            <div className="referral-code-section">
                <span className="referral-code">{profiledata?.referral_code}</span>
                <button className="copy-button" onClick={copyReferralCode}>{cvalue}</button>
            </div>
           <RWebShare
                data={{
                  url: `${window?.location?.origin}/home?refer-code=${profiledata?.referral_code?profiledata?.referral_code:""}`,
                  title: `Share`,
                }}
                closeText={`Close`}
              >
                <div className="Invite_Friend_Btn">
                <button>Invite</button>
            </div>
         </RWebShare>

</div>
       <div className="refer-and-earn">
      <h2 >How <span>Refer & Earn</span> Works?</h2>
      <div class="stepper-wrapper">
  <div class="stepper-item">
    <div class="step-counter">1</div>
    <div class="step-name">Open an <br></br>Sweepcoins account.</div>
  </div>
  <div class="stepper-item">
    <div class="step-counter">2</div>
    <div class="step-name">Refer friend & loved ones to Sweepcoins.</div>
  </div>
  <div class="stepper-item active">
    <div class="step-counter">3</div>
    <div class="step-name">Your referred friend opens Sweepcoins account.</div>
  </div>
  <div class="stepper-item">
    <div class="step-counter">4</div>
    {/* <div class="step-name">Earn credit when invitee makes their first purchase. Plus, if they complete the purchase within 24 hours of signing up, they'll receive an additional 10% credit!</div> */}
    <div class="step-name">
    Get credits when your invitee makes their first purchase! Plus, earn an extra 10% if they purchase within 24 hours of signing up.
    </div>
  </div>
</div>

    </div>




          
                       </div>

                        </>:
                         (<div className="redeemprizebest">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="redeemformadd">
                                        <h4>ENTER SWEEPS ENTRY CODE</h4>
                                        <div style={{ position: "relative"}}>
                                        <input type="text"      style={{
      paddingRight: "35px", 
      width: "100%", 
    }}
 className="form-control" value={sweepsCode} onChange={(e) => setSweepsCode(e.target.value.trim())} onKeyDown={(e) => onSubmitLogin(e)}/>
                                        {sweepsCode && (
    <div
      onClick={clearInput}
      style={{
        position: "absolute",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        cursor: "pointer",
        display: "flex", 
        alignItems: "center",
        justifyContent: "center",
        width: "20px", 
        height: "20px",
      }}
    >
      <img
        src={CloseBtn}
        alt="Clear"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain", 
        }}
      />
    </div>
  )}

                                        </div>
                                        {(errorSweepsCode !== "" && sweepsCode === "") && (<div className="danger-colorset">{errorSweepsCode}</div>)}
                                        <button onClick={() => submitCode()}>SUBMIT CODE</button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="redeemprice_wrapper">
                                        <h1 className="wow fadeInLeft">SWEEPS COINS WALLET</h1>
                                        <h4 className="avilableredeemprice">YOUR AVAILABLE CREDITS </h4>
                                        <h5 className="reedemPrice">
                                            {(profiledata?.user_balance === null || profiledata?.user_balance === 0) ? 0 : profiledata?.user_balance / 100}
                                        </h5>
                                        <h4>CLAIM CREDITS BELOW</h4>
                                        <div className="redeemprizewrapper">
                                            <div className="row">
                                                {products.length > 0 ? products && products?.map((elm, index) => {
                                                    return(
                                                        <div className="col-4" key={index}>
                                                            <img src={elm?.product_img_path} onClick={() => addredeemCredit(elm)} alt={"redeem price_" + index} />
                                                        </div>
                                                    )
                                                }) : (<h5>Games Not Found !</h5>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)}

                    </div>
                </div>
            </div>
        </div>
        {commonPopup?.open && (<CommonModal DiscardAccountData={DiscardAccountData} commonPopup={commonPopup} setCommonPopup={setCommonPopup} />)}
        {commonPopup?.open && (<div className="ModalBackground"></div>)}

        {RedeemCrditModalState?.open && (<RedeemCreditsModal RequestProcessModel={RequestProcessModel} setRequestProcessModel={setRequestProcessModel} UserBlalance={profiledata?.user_balance} accessToken={accessToken} RedeemCrditModalState={RedeemCrditModalState} setRedeemCrditModalState={setRedeemCrditModalState} />)}
        {RedeemCrditModalState?.open && (<div className="redeembackground" onClick={() => setRedeemCrditModalState({...RedeemCrditModalState, open: false})}></div>)}

        {RequestProcessModel?.open && (<div className="statusPendingModel">
            <img src={CashPaymentIcon} alt="icon" />
            <h1>Your <b>{productidObjects[RequestProcessModel?.data?.product_id].name}</b> credit request is currently in progress. You will receive a confirmation email regarding your credit request with the request id<div className="request_id_pass">{RequestProcessModel?.data?.request_id}</div></h1>
            <button className="btn" onClick={() => setRequestProcessModel({...RequestProcessModel, open: false, data: {}})} >Ok</button>
        </div>)}
        {RequestProcessModel?.open && (<div className="ModalBackground" onClick={() => setRequestProcessModel({...RequestProcessModel, open: false, data: {}})}></div>)}
    </section>)
}

export default Account;