import React, { useEffect, useState } from "react";
import { USER_CREDIT_REQUEST_API_URL } from "../../Shared/constant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CallLogoutUser, IsSiteIsBlockCheck, getUser, getOverCallingGeoLocation, getGeoCoplyMessage, getRegioLcTime, getGeoCoplyLocation, getSpoofingDetection } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import sound from '../../../assets/audio/game-bonus-144751.wav'
const RedeemCreditsModal = (props) => {
    const { UniqueBrowserId } = useSelector((state) => state.allReducers);
    const { RedeemCrditModalState, setRedeemCrditModalState, accessToken, UserBlalance, RequestProcessModel, setRequestProcessModel } = props;
    const [ creditAmount, setCreditAmount ] = useState("");
    const dispatch = useDispatch();
    const exceptThisSymbols = ["e", "E", "+", "-"];
    
    // error credit ammount
    const [ errorCreditAmount, setErrorCreditAmount ] = useState("");

    useEffect(() => {
        const myInput = document.getElementById("redeemInputBox");

        myInput?.addEventListener("focus", function() {
            myInput.placeholder = "";
        });

        myInput?.addEventListener("blur", function() {
            myInput.placeholder = "Number of entries you want to redeem";
        });
    }, []);

    const isDecimal = (value) => {
        // Regular expression to match a decimal number
        var decimalPattern = /^\d*\.\d+$/;

        // Use test method to check if the value matches the pattern
        return decimalPattern.test(value);
    }
    
    const RedeemCreditValueConvert = (value) => {
        if(isDecimal(value)) {
            var SplitDecimal = value?.split(".");
            var addition = parseInt(SplitDecimal[0].toString() + SplitDecimal[1].toString());
            return SplitDecimal[1]?.length === 1 ? parseInt(addition * 10) : parseInt(addition);
        } else{
            return parseInt(value * 100);
        }
    };

    const submitRedeem = () => {        
        if(creditAmount !== "" && creditAmount.toString() !== "0") {
            if(parseInt(creditAmount) <= parseInt(UserBlalance)) {
                dispatch(getRegioLcTime(""));
                dispatch(getOverCallingGeoLocation(true));
                GetGeoLicenceFunction("credit-request"); 
                document.getElementById("pageisLoading").style.display = "flex";
                setErrorCreditAmount("");
            } else {
                setErrorCreditAmount("Available credit should be more than claim credit.");
            };
        } else {
            if(creditAmount === "") {
                setErrorCreditAmount("Redeem amount cannot be empty");
            } else if(creditAmount.toString() === "0") {
                setErrorCreditAmount("Please enter valid amount");
            } else {
                setErrorCreditAmount("");
            }
        }
    };

    const GetGeoLicenceFunction = async (url) => {
        window.axios.get(`${process.env.REACT_APP_BASE_URL}user/geocomply/license/${UniqueBrowserId}`, {
        headers: {
            'Content-Type': 'application/json',
        }}).then(function (result) {
            if(result?.status === 200) {
            GetGeoLocate(result?.data?.data?.license, url);
            dispatch(getGeoCoplyMessage(""));
            };
        }).catch(function (result) {
            dispatch(getGeoCoplyMessage(""));
        });
    };
    
    // Geo location api
    const GetGeoLocate = (jsonData, url) => {
        var geoClient = window.GcHtml5.createClient();
        geoClient.setUserId(UniqueBrowserId);
        geoClient.setReason(url);
        geoClient.setLicense(jsonData);
        geoClient.events.on('hint', function(reason, data) {
        }).on('engine.success', function(text, xml) {
            LocationPermission(text);
            dispatch(getGeoCoplyLocation(text));
            // localStorage.setItem("GeoLocationToken", text);
        }).on('*.failed', function(code, message) {
            if (this.event === 'revise.failed' || this.event === 'config.failed' || this.event === 'engine.failed') {
                if (code === geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION) {
                    //network issue, we can add retry logic if needed
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_INVALID_FORMAT || code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED) {
                    //e.g. update license and retry geolocating
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS) {
                    //it's safe to be ignored, or we can wait for previous request to complete and try again
                } else {
                    //something went wrong with GeoComply service, your license or your custom data
                    //e.g. submit log to record the issue
                }
            } else if (this.event === 'browser.failed') {
                //it's safe to be ignored, you can remove this block or add your own logic
            }
        });
        geoClient.request();
    };
    
    // location Permissions api
    const LocationPermission = async (text) => {
        console.log("Redeem Permission");
        const jsonData = JSON.stringify({ 
        "id": UniqueBrowserId,
        "data": text
        });
        window.axios.post(`${process.env.REACT_APP_BASE_URL}user/geocomply/permission`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (result) {
            if(result?.status === 200) {
            dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
            if(result?.data?.data?.status === true) {
                dispatch(getOverCallingGeoLocation(false));
                submitRedeemGeoFunction();
                // success code
                localStorage.removeItem("starttime");
                localStorage.removeItem("targettime");
                dispatch(getRegioLcTime(result?.data?.data?.re_geolocation));
            }
            dispatch(getSpoofingDetection(result?.data?.data?.spoofing_detection));
            if(result?.data?.data?.status === false) {
                document.getElementById("pageisLoading").style.display = "none";
                dispatch(getGeoCoplyMessage(result?.data?.data?.troubleshooter));
                if(result?.data?.data?.spoofing_detection != null){
                    // add spoofing detection popup
                    console.log("spoofing detection");
                }
            }
            } else if(result?.status === 400) {
                dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
            };
        })  
        .catch(function (result) { 
        document.getElementById("pageisLoading").style.display = "none";
        if(result?.response?.data?.error === "Geo-comply failed.") {
            localStorage.setItem("UniquID", "id" + Math.random().toString(16).slice(2));
        } else if(result?.status === 400) {
            dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
        };
        });
    };

    // user credit request api function
    const submitRedeemGeoFunction = () => {
        if(creditAmount !== "" && creditAmount.toString() !== "0") {
            if(parseInt(creditAmount) <= parseInt(UserBlalance)) {
                window.axios.get(`${USER_CREDIT_REQUEST_API_URL}${RedeemCrditModalState?.RedeemData?.id}/${RedeemCreditValueConvert(creditAmount)}` , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    if(result?.data) {
                        document.getElementById("pageisLoading").style.display = "none";
                        setRedeemCrditModalState({...RedeemCrditModalState, open: false});
                        // dispatch(claimUserWallet(result?.data?.data));
                        setRequestProcessModel({...RequestProcessModel, open: true, data: result?.data?.data})
                        dispatch(getUser(result?.data?.data?.user));
                        setCreditAmount("");
                        setErrorCreditAmount("");
                        new Audio(sound).play();
                        toast.success(result.data.msg)

                    }
                }).catch(function (result) {
                    document.getElementById("pageisLoading").style.display = "none";
                    toast.error(result.response.data.error);
                    if(result?.response?.status === 403) {
                        dispatch(CallLogoutUser());
                        localStorage.removeItem("access_tokens");
                    };
                });
            } else {
                setErrorCreditAmount("Available credit should be more than claim credit.");
            };
        } else {
            if(creditAmount === "") {
                setErrorCreditAmount("Redeem amount cannot be empty");
            } else if(creditAmount.toString() === "0") {
                setErrorCreditAmount("Please enter valid amount");
            } else {
                setErrorCreditAmount("");
            }
        };            
    };

    // valid ammount
    const ValidCreditCard = (value) => {
        if(value === "") {
            setErrorCreditAmount("Redeem amount cannot be empty");
        } else if(value.toString() === "0") {
            setErrorCreditAmount("Please enter valid amount");
        } else {
            setErrorCreditAmount("");
        }
    }

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    const onChange = (e) => {
        const inputValue = e.target.value;

        if (e.target.type === "number") {
            // const isNumeric = /^\d+$/.test(inputValue);
            const isNumeric = /^\d*\.?\d{0,2}$/.test(inputValue);

            if (!isNumeric && inputValue) {
                e.target.value = creditAmount;
            }
            if (parseFloat(inputValue) <= 0) {
                e.target.value = "";
            }
            if (parseFloat(inputValue) > 999999) {
                e.target.value = creditAmount;
                console.log("Value should not be greater than 10 lakhs");
            }
        }
        setCreditAmount(e.target.value)
    };
    var validate = function(e, decimalPlaces) {
        // Get the input value
        let value = e.target.value;
            
        // Define a regular expression to match the format you want
        const regex = new RegExp(`^-?\\d+(\\.\\d{0,${decimalPlaces}})?$`);
        
        // Test the input value against the regular expression
        if (!regex.test(value)) {
            // If it doesn't match, remove any invalid characters
            value = value.replace(/[^0-9.-]/g, '');
            
            // Ensure only two decimal places
            const parts = value.split('.');
            if (parts[1] && parts[1].length > decimalPlaces) {
                parts[1] = parts[1].substring(0, decimalPlaces);
                value = parts.join('.');
            }
            
            // Update the input value
            e.target.value = value;
        }
    }

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputredeem")) {
            document.activeElement.blur();
        }
    });

    return(<div className="RedeemCreditModalMain">
        <div className="closebtn" onClick={() => setRedeemCrditModalState({...RedeemCrditModalState, open: false})}>
            <svg preserveAspectRatio="xMidYMid meet" data-bbox="29.6 26 148 148" xmlns="http://www.w3.org/2000/svg" viewBox="29.6 26 148 148" role="presentation" aria-hidden="true">
                <g><path d="M177.6 147.3L130.3 100l47.3-47.3L150.9 26l-47.3 47.3L56.3 26 29.6 52.7 76.9 100l-47.3 47.3L56.3 174l47.3-47.3 47.3 47.3 26.7-26.7z"></path></g>
            </svg>    
        </div>
        <h1>{RedeemCrditModalState?.title}</h1>
        <div className="redeempics">
            <img src={RedeemCrditModalState?.RedeemData?.product_img_path} alt={"redeempics_" + RedeemCrditModalState?.RedeemData?.id} /> 
            <div className="redeemcreditform">
                <input 
                    type="number" 
                    id="redeemInputBox"
                    className="form-control inputredeem" 
                    value={creditAmount} 
                    onKeyUp={(e) => ValidCreditCard(e.target.value)}
                    // onChange={(e) =>{
                    //     if(e.target.value <= 0){
                    //         e.target.value = "";
                    //     }
                    //     if(e.target.value>10000000){
                    //         e.target.value = creditAmount
                    //     }
                    //     setCreditAmount(e.target.value);
                    // }} 
                    onChange={onChange}
                    min="0" 
                    onKeyPress={preventMinus} 
                    onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                    // onInput={(e) => validate(e, 2)}
                    placeholder="Number of entries you want to redeem"
                />
                {(errorCreditAmount !== "") && (<div className="danger-colorset">{errorCreditAmount}</div>)}
                <button className="btn" onClick={() => submitRedeem()} >Submit</button>
            </div>
        </div>
    </div>)
}

export default RedeemCreditsModal;