/* eslint-disable */
import React from "react";
// import CreditCardImage from "../../assets/img/payment/credit-card.png";
import CreditCardImage from "../../assets/img/cc.png";
// import DebitCardImage from "../../assets/img/payment/debit-card.png";
import DebitCardImage from "../../assets/img/dd.png";
import CrptoImage from "../../assets/img/payment/Crypto.png";
// import DirectPaymentImage from "../../assets/img/payment/Direct-Pyment.png";
import DirectPaymentImage from "../../assets/img/bank.png";
import SecureImage from "../../assets/img/secure.webp";
import CashApp from "../../assets/img/CashApp.svg";
import creditIcon from "../../assets/img/creditIcon.svg";
import CashPayComponent from "./CashPayComponent";

const PaymentMethedModal = (props) => {
    const { PaymentMethodModalState, SubmitPaymentMethod, setTypePayment, callSelectMethod, setStepUpdate, minimunamounterror } = props;

    const SubmitPaymentFct = (url) => {
        // if(url === "credit_card") {
        //     setTypePayment("inoviopay");
        // } else {
            setTypePayment(url);
        // };
        if(props?.TypePayment == url && url === "cash_app"){
            return null;
        }
        SubmitPaymentMethod(url);
        // if(url === "cash_app") {
        //     setStepUpdate("payment_option")
        //     callSelectMethod("cash_app")
        // }
    };

    return(<div className="payment_method_wrapper">
        <h4>{PaymentMethodModalState?.title}</h4>
        <div className="payment_wrapper">
            <ul>
                <li onClick={() => SubmitPaymentFct("debit_card")}>
                    <img src={DebitCardImage} alt="DebitCardImage" />
                   Card Payment
                    <span className="payment_name_class">(Commerce Expert)</span>
                </li>
                {/* <li onClick={() => SubmitPaymentFct("credit_card")}>
                    <img src={CreditCardImage} alt="CreditCardImage" />
                    Credit Card
                <span className="payment_name_class">(Inovio Payment)</span>
                </li> */}
            {/* </ul>
            <ul>
                <li
                    onClick={() => SubmitPaymentFct("direct_payment")}
                    id="wrapper-pay-buttons"
                    className="wrapper-pay-buttons"
                >
                    <img src={DirectPaymentImage} alt="DirectPaymentImage" />
                    Direct Payment
                    <span className="payment_name_class">(Paynote)</span>
                </li> */}
                <li onClick={() => SubmitPaymentFct("crypto")}>
                    <img src={CrptoImage} alt="CrptoImage" />
                    Crypto
                    <span className="payment_name_class">(Rocketfuel)</span>
                </li>
            </ul>
            <ul>
                {/* <li className="" onClick={() => SubmitPaymentFct("cash_app")}>
                    <img src={CashApp} alt="CashApp" />
                    CashApp
                    {props?.paymentMethod == "cash_app" && (
                        <React.Fragment>
                            <CashPayComponent GetGeoLicenceFunction={props?.GetGeoLicenceFunction} {...props?.props} TypePayment={props?.TypePayment} SecureImage={SecureImage} PaymentContinueStepGeo={props?.PaymentContinueStepGeo} callBackInitPayment={props?.callPaymentCompleteCashApp} amount={props?.selectedProduct.price} />
                        </React.Fragment>
                    )}
                </li> */}
                {/* <li className="" onClick={() => callSelectMethod("google_pay")}>
                    <img src={creditIcon} alt="creditIcon" />
                    Other
                </li> */}
            </ul>
        </div>
        {
            minimunamounterror &&(
            <p className="payment_msg_error">
            {minimunamounterror}
            </p>
            )
        }
        <img src={SecureImage} className="secureimage" alt="SecureImage" />
    </div>)
}
export default PaymentMethedModal;