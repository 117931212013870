/* eslint-disable */
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Country, State, City }  from 'country-state-city';
import SucessIcon from "../../../assets/img/success.svg";
import ErrorIcon from "../../../assets/img/error.svg";
import { Button } from "react-bootstrap";
import CountrySelectInput from "../CountrySelectInput/CountryMobileInput";
import { customerDetailsGet, cardDetailsGet, AddMyProduct, getOverCallingGeoLocation, getSpoofingDetection, getRegioLcTime, IsSiteIsBlockCheck, getGeoCoplyMessage, getGeoCoplyLocation, getAccessToken, checkLogin, getUser, OrderIsInProcessModalStateFct } from "../../../redux/actions";
import CustomMendotoryMsg from "../CustomMendotoryMsg";
import { useDispatch, useSelector } from "react-redux";
import { PLACE_PRODUCT_ORDER_API_URL, PAYMENT_PLACE_ORDER_API_URL, USER_CRYPTO_PAYMENT_API_URL, HOME_URL, CHECK_OUT_PACKAGE } from "../../Shared/constant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CashPayComponent from "../CashPayComponent";
import PropTypes from 'prop-types'
import SecureImage from "../../../assets/img/secure.webp";
import RightArrowIcon from "../../../assets/img/RightArrowIcon.svg"
import sound from "../../../assets/audio/apple_pay_sound.wav"
import PaymentMethedModal from "../PaymentMethedModal";
import OrderInProcessModal from "../OrderInProcessModal";
import OrderProcessCancelModal from "../OrderProcessCancelModal";
// import RocketFuelPayment from "../RocketFuelPayment";

const CheckoutModal = (props) => {
    const audioMedia = new Audio(sound);
    const Navigate = useNavigate();
    const { selectedProduct, checkoutPoup, setCheckoutPoup, SuccessPopup, setSuccessPopup } = props;
    const { accessToken, customerDetail, UniqueBrowserId, OrderIsInPrcessModalState, profiledata } = useSelector((state) => state.allReducers);
    const phone = profiledata?.phone;
    const phoneCountry = profiledata?.phone?.replace("-", " ")?.split(" ");
    const lastTenDigits = phone?.slice(-10);
    const [ MobileNo, setMobileNo ] = useState({
        countrycode: phoneCountry?.length>0 && profiledata?.phone?.includes(" ")?phoneCountry?.[0]?.replace("-NaN", ""):"1",
        number: lastTenDigits || "",
    });
    const [fname, setFname] = useState(profiledata?.first_name || "");
    const [lname, setLname] = useState(profiledata?.last_name || "");
    const [email, setEmail] = useState(profiledata?.email || "");
    const [ SelectCountry, setSelectCountry ] = useState("United States");
    const [rcode,setRCode] = useState("")
    // mendotory error state
    const [ CustomError, setCustomError ] = useState(false);
    const [ stepUpdate, setStepUpdate ] = useState("yourdetails");
    // const [ stepUpdate, setStepUpdate ] = useState("payment_option");
    const dispatch = useDispatch();
    const [ creditCard, setCreditCard ] = useState("");
    const [ creditCardError, setCreditCardError ] = useState("");
    const [ expiryDate, setExpiryDate ] = useState("");
    const [ cvv, setCVV ] = useState("");
    const [ cardholderName, setCardholderName ] = useState("");
    const [ MobileError, setMobileError ] = useState("");
    const [ paymentDropdown, setPaymentDropdown ] = useState(false);
    const [ errorEmail, setErrorEmail ] = useState("");
    const [ errorExpiryDate, setErrorExpiryDate ] = useState("");
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    const [ Loader, setLoader ] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("card")
    const [ showHideCvv, setShowHideCvv ] = useState(false);
    const navigate = useNavigate();

    const {payment_id} = useParams()

    // const [rocketFuelOptions, setRocketFuelOptions] = useState({
    //     order: {},
    //     rkfl: {},
    //     iframe_url: "",
    //     fetch_url: "",
    //     data: {},
    // });

    useEffect(()=>{
        if(payment_id){
            if(payment_id == customerDetail?.id){
                setPaymentMethod("cash_app")
                setStepUpdate("payment_option")
            }
        }
    },[customerDetail?.id, payment_id])
    // aniket code
    const [ PaymentMethodModalState, setPaymentMethodModalState ] = useState({
        open: false,
        title: "",
    });
    const [ TypePayment, setTypePayment ] = useState("");
    const [ DontWorryModal, setDontWorryModal ] = useState({
        open: false,
        flag: "",
    });
    useEffect(() => {
        const SaveTokenAPI = async (res) => {
            if(typeof res.data == "object"){
                if(res?.data?.type == "response"){
                    if(res?.data?.STATUS === 200){
                        CloseCheckoutModal();
                        toast(res?.data?.data?.msg);
                        dispatch(cardDetailsGet(res.data.data?.data));
                        dispatch(customerDetailsGet(res.data.data?.data));
                        setSuccessPopup({...SuccessPopup, open: true, title: "Thank you for the payment!"});

                    }else{
                        if(typeof res?.data?.data?.error == "string"){
                            toast.info(res?.data?.data?.error)
                        }else if(res?.data?.data?.msg){
                            toast.info(res?.data?.data?.msg)
                        }else{
                            const error = res?.data?.data?.error;
                            if (error && typeof error === 'object') {
                                Object.keys(error).forEach(key => {
                                    const value = error[key];
                                    toast.info(`${key}: ${value}`);
                                });
                            }
                        }
                    }
                }
            }
        };
        addEventListener("message", SaveTokenAPI);
        return ()=>{
            removeEventListener("message", SaveTokenAPI);
        }
    }, []); 
    // select credit card
    const creditcardNo = (e) => {
        setCreditCard(e.target.value.trim().replace(/\s+/g, "").replace(/[^0-9]/gi, "").substr(0, 23));
    };

    const cc_format = (value) => {
        const v = value
          .replace(/\s+/g, "")
          .replace(/[^0-9]/gi, "")
          .substr(0, 23);
        const parts = [];
      
        for (let i = 0; i < v.length; i += 4) {
          parts.push(v.substr(i, 4));
        }
      
        return parts.length > 1 ? parts.join(" ") : value;
    }
    
    // select country 
    const SelectCoutry = (event) => {
        setSelectCountry(event.target.value);
    };

    // fill form then submit
    const PaymentSelectMethod = () => {
        if(fname !== "" && lname !== "" && email !== "" && MobileNo?.number?.length === 10 && SelectCountry !== "") {
            if(EmailRegex.test(email) === true) {
                setStepUpdate("paymentdetailsmethod");
            } else {
                if(EmailRegex.test(email) === false) {
                    setErrorEmail("Please Enter Valid Email id");
                } else {
                    setErrorEmail("");
                }
                if(MobileNo?.number?.length < 10) {
                    setMobileError("Please enter 10 digit number");
                } else {
                    setMobileError("");
                }
            }
        } else {
            setCustomError(true);
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
            if(MobileNo?.number === "") {
                setMobileError("Phone Number cannot be empty");
            } else if(MobileNo?.number?.length < 10) {
                setMobileError("Please enter 10 digit number");
            } else {
                setMobileError("");
            }
        };
        
    };

    useEffect(() => {
        if(OrderIsInPrcessModalState !== undefined && OrderIsInPrcessModalState?.open) {
            PaymentSelectMethod();
        };
    }, [ OrderIsInPrcessModalState ]);

    // select payment method and submit function
    const [minimunamounterror,setMinumumAmountError] = useState("")
    const SubmitPaymentMethod = (url) => {
        setMinumumAmountError("")
        if(fname !== "" && lname !== "" && email !== "" && MobileNo?.number?.length === 10 && SelectCountry !== "") {
            if(EmailRegex.test(email) === true) {
                if(url === "debit_card" || url === "credit_card") {
                    PaymentContinueStep();
                    setPaymentMethod("card")
                } else if(url === "crypto") {
                    // props?.selectedProduct?.price
                    // PaymentContinueStep(url);
                    if (props?.selectedProduct?.price < 5) {
                        setMinumumAmountError("To proceed with a crypto payment, the minimum package amount required is $5.")
                    } else {
                        // Continue with the payment process
                        PaymentContinueStep(url);
                    }
                
                } else if(url==="direct_payment") {
                    if(callpaynotebtn){
                        console.log("after place order click")
                        null
                    }else{
                        PaymentContinueStep("direct_payment");
                        console.log("place order call")
                    }
                    
                }else{
                    PaymentContinueStep(url);
                }
            } else {
                if(EmailRegex.test(email) === false) {
                    setErrorEmail("Please Enter Valid Email id");
                } else {
                    setErrorEmail("");
                }
                if(MobileNo?.number?.length < 10) {
                    setMobileError("Please enter 10 digit number");
                } else {
                    setMobileError("");
                }
            }
        } else {
            setCustomError(true);
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
            if(MobileNo?.number === "") {
                setMobileError("Phone Number cannot be empty");
            } else if(MobileNo?.number?.length < 10) {
                setMobileError("Please enter 10 digit number");
            } else {
                setMobileError("");
            }
        };
    };

    const PaymentContinueStep = (payment_url) => {
        document.getElementById("checkoutflag")?.classList?.remove("active");
        if(fname !== "" && lname !== "" && email !== "" && MobileNo?.number?.length === 10 && SelectCountry !== "") {
            if(EmailRegex.test(email) === true) {
                dispatch(getRegioLcTime(""));
                dispatch(getOverCallingGeoLocation(true));
                document.getElementById("pageisLoading").style.display = "flex";
                setCustomError(false);
                setErrorEmail("");
                GetGeoLicenceFunction("Place-order", payment_url);
            } else {
                if(EmailRegex.test(email) === false) {
                    setErrorEmail("Please Enter Valid Email id");
                } else {
                    setErrorEmail("");
                }

                if(MobileNo?.number?.length < 10) {
                    setMobileError("Please enter 10 digit number");
                } else {
                    setMobileError("");
                }
            }
        } else {
            setCustomError(true);
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
            if(MobileNo?.number === "") {
                setMobileError("Phone Number cannot be empty");
            } else if(MobileNo?.number?.length < 10) {
                setMobileError("Please enter 10 digit number");
            } else {
                setMobileError("");
            }
        };
    }

    const GetGeoLicenceFunction = async (url, payment_url) => {
        window.axios.get(`${process.env.REACT_APP_BASE_URL}user/geocomply/license/${UniqueBrowserId}`, {
        headers: {
            'Content-Type': 'application/json',
        }}).then(function (result) {
            if(result?.status === 200) {
            GetGeoLocate(result?.data?.data?.license, url, payment_url);
            dispatch(getGeoCoplyMessage(""));
            };
        }).catch(function (result) {
            dispatch(getGeoCoplyMessage(""));
        });
    };
    
    // Geo location api
    const GetGeoLocate = (jsonData, url, payment_url) => {
        var geoClient = window.GcHtml5.createClient();
        geoClient.setUserId(UniqueBrowserId);
        geoClient.setReason(url);
        geoClient.setLicense(jsonData);
        geoClient.events.on('hint', function(reason, data) {
        }).on('engine.success', function(text, xml) {
            LocationPermission(text, payment_url);
            dispatch(getGeoCoplyLocation(text));
            // localStorage.setItem("GeoLocationToken", text);
        }).on('*.failed', function(code, message) {
            if (this.event === 'revise.failed' || this.event === 'config.failed' || this.event === 'engine.failed') {
                if (code === geoClient.ErrorCodes.CLNT_ERROR_NETWORK_CONNECTION) {
                    //network issue, we can add retry logic if needed
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_INVALID_FORMAT || code === geoClient.ErrorCodes.CLNT_ERROR_LICENSE_EXPIRED) {
                    //e.g. update license and retry geolocating
                } else if (code === geoClient.ErrorCodes.CLNT_ERROR_REQUEST_GEOLOCATION_IN_PROGRESS) {
                    //it's safe to be ignored, or we can wait for previous request to complete and try again
                } else {
                    //something went wrong with GeoComply service, your license or your custom data
                    //e.g. submit log to record the issue
                }
            } else if (this.event === 'browser.failed') {
                //it's safe to be ignored, you can remove this block or add your own logic
            }
        });
        geoClient.request();
    };
    
    // callback call
    const callGeoTimeApi = async (re_geolocation) => {
        if(re_geolocation !== null) {
            var reGeoLcTime = re_geolocation * 1000;
            localStorage.setItem("reGeoLcTime", reGeoLcTime.toString());
            var currentTimeMillis = Date.now();
            var currentTimeString = currentTimeMillis.toString();
            localStorage.setItem("reGeoLcTimeStamp", currentTimeString);
            setTimeout(() => {
                GetGeoLicenceFunction("Periodic");
            }, reGeoLcTime);
        }
    };
    
    // location Permissions api
    const LocationPermission = async (text, payment_url) => {
        const jsonData = JSON.stringify({ 
        "id": UniqueBrowserId,
        "data": text
        });
        window.axios.post(`${process.env.REACT_APP_BASE_URL}user/geocomply/permission`, jsonData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        }).then(function (result) {
            if(result?.status === 200) {
            dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
            if(result?.data?.data?.status === true) {

                if(payment_url == "cash_app"){
                        setStepUpdate("payment_option")
                        callSelectMethod("cash_app")
                }else{
                    PaymentContinueStepGeo(payment_url);
                }
                dispatch(getOverCallingGeoLocation(false));
                // success code
                // callGeoTimeApi(result?.data?.data?.re_geolocation);
                dispatch(getRegioLcTime(result?.data?.data?.re_geolocation));
                localStorage.removeItem("starttime");
                localStorage.removeItem("targettime");
            }
            dispatch(getSpoofingDetection(result?.data?.data?.spoofing_detection));
            if(result?.data?.data?.status === false) {
                document.getElementById("pageisLoading").style.display = "none";
                dispatch(getGeoCoplyMessage(result?.data?.data?.troubleshooter));
                if(result?.data?.data?.spoofing_detection != null){
                    // add spoofing detection popup
                }
            }
            } else if(result?.status === 400) {
                dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
            };
        })  
        .catch(function (result) { 
        document.getElementById("pageisLoading").style.display = "none";
        if(result?.response?.data?.error === "Geo-comply failed.") {
            localStorage.setItem("UniquID", "id" + Math.random().toString(16).slice(2));
        } else if(result?.status === 400) {
            dispatch(IsSiteIsBlockCheck(result?.data?.data?.status));
        };
        });
    };
    // useEffect(()=>{
    //     if(props?.paymentState){
    //         setStepUpdate("payment_option")
    //     }
    // },[])

    // continue steps with order placed function
    const ProductId = selectedProduct.id;
    const PaymentContinueStepGeo = async (payment_url) => {
        document.getElementById("checkoutflag")?.classList?.remove("active");
        if(fname !== "" && lname !== "" && email !== "" && MobileNo?.number?.length === 10 && SelectCountry !== "") {
            if(EmailRegex.test(email) === true) {
                setCustomError(false);
                setErrorEmail("");
                const jsonData = JSON.stringify({ 
                    "first_name" : fname, 
                    "last_name" : lname, 
                    "country" : SelectCountry, 
                    "email" : email.toLowerCase(), 
                    "phone" : MobileNo?.countrycode.toString() + " " + MobileNo?.number.toString(),
                    "cart" : ProductId,
                    "total_amount": parseInt(selectedProduct.price * 100),
                });
                const response = await  window.axios.post(PLACE_PRODUCT_ORDER_API_URL, jsonData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    if(payment_url === "crypto") {
                        setTimeout(() => {
                            dispatch(customerDetailsGet(result?.data?.data));
                            localStorage.setItem("order_place", JSON.stringify(result?.data?.data))
                            cryptoAPICalling(result?.data?.data);
                        }, 100);
                    } else if (payment_url ==="direct_payment") {
                        document.getElementById("pageisLoading").style.display = "none";
                        SubmitPaymentFct(result?.data?.data?.id)
                        setCallPayNoteBtn(true)
                    } else {

                        if(payment_url !='cash_app'){
                            document.getElementById("pageisLoading").style.display = "none";
                        }
                        setTimeout(()=> {
                            dispatch(customerDetailsGet(result?.data?.data));
                            localStorage.setItem("order_place", JSON.stringify(result?.data?.data))
                            // setStepUpdate("paymentdetails");
                            // toast.success(result.data.msg);
                            TokenizingCards(result?.data?.data);
                            setPaymentMethodModalState({
                                ...PaymentMethodModalState,
                                open: false,
                                title: "",
                            });
                            setErrorEmail("");
                            // CloseCheckoutModal();
                        }, 100);
                        return result?.data?.data;
                    }
                    // if(payment_url !== "crypto") {
                    //     if(payment_url !='cash_app'){
                    //         document.getElementById("pageisLoading").style.display = "none";
                    //     }
                    //     setTimeout(()=> {
                    //         dispatch(customerDetailsGet(result?.data?.data));
                    //         localStorage.setItem("order_place", JSON.stringify(result?.data?.data))
                    //         // setStepUpdate("paymentdetails");
                    //         // toast.success(result.data.msg);
                    //         TokenizingCards(result?.data?.data);
                    //         setPaymentMethodModalState({
                    //             ...PaymentMethodModalState,
                    //             open: false,
                    //             title: "",
                    //         });
                    //         setErrorEmail("");
                    //         // CloseCheckoutModal();
                    //     }, 100);
                    //     return result?.data?.data;
                    // }
                })  
                .catch(function (result) {
                    document.getElementById("pageisLoading").style.display = "none";
                    dispatch(customerDetailsGet(""));
                    toast.error(result.response.data.error);
                    if(result?.response?.data?.detail === "Token expired.") {
                        AccessTokenCheckLogout();
                    };
                    // dispatch(CallLogoutUser());
                });
                if(payment_url == "cash_app"){
                    return response;
                }
            } else {
                if(EmailRegex.test(email) === false) {
                    setErrorEmail("Please Enter Valid Email id");
                } else {
                    setErrorEmail("");
                }

                if(MobileNo?.number?.length < 10) {
                    setMobileError("Please enter 10 digit number");
                } else {
                    setMobileError("");
                }
            }
        } else {
            setCustomError(true);
            if(email === "") {
                setErrorEmail("Email id cannot be empty");
            } else if(EmailRegex.test(email) === false) {
                setErrorEmail("Please Enter Valid Email id");
            } else {
                setErrorEmail("");
            }
            if(MobileNo?.number === "") {
                setMobileError("Phone Number cannot be empty");
            } else if(MobileNo?.number?.length < 10) {
                setMobileError("Please enter 10 digit number");
            } else {
                setMobileError("");
            }
        };
    }

    const [callpaynotebtn,setCallPayNoteBtn] = useState(false)
    const SubmitPaymentFct = (order_id) => {
        const script = document.createElement('script');
        script.src = 'https://developers.seamlesschex.com/docs/checkoutjs/sdk-min.js';
        script.type = 'text/javascript';
        script.async = true;
        document.body.appendChild(script);
  
        script.onload = () => {
          // Initialize the PAYNOTE object when the script is loaded
        //  pk_test_01J42CTQND6T5C7PDHJ0EX8N1J TEST KEY
        // publicKey: "pk_01J2Y6XHTQF6C10TK8EGQJJSYW",
          const objRequestRedirect = {
            publicKey: process.env.REACT_APP_PAY_NOTE_KEY,
            sandbox: process.env.REACT_APP_SANDBOX_KEY,
            saveBankDetails: false,
            displayMethod: 'redirect',
            paymentToken: 'pay_tok_SPECIMEN-' + Math.random(),
            widgetContainerSelector: 'wrapper-pay-buttons',
            storeName: 'Sweeps Coins Games',
            style: {
              buttonClass: 'btn green-btn btn-block no-overflow',
              buttonColor: '#00b660',
              buttonLabelColor: '#ffffff',
              buttonLabel: 'PAY',
            },
            lightBox: {
              redirectUrl: `${window.location.origin}/home`,
              cancelUrl: `${window.location.origin}/home`,
              title: 'Sweeps Coins Pay',
              subtitle: 'Package purchase',
              logoUrl: '',
              formButtonLabel: 'PAY',
              show_cart_summary: false,
            },
            checkout: {
              totalValue: Number(props?.selectedProduct?.price),
              currency: 'USD',
              description: order_id,
              items: [
                { title: props?.selectedProduct?.name, price: Number(props?.selectedProduct?.price) },
              ],
              customerEmail: email,
              customerFirstName: fname,
              customerLastName: lname,
            },
          };
          const paynoteRedirect = new PAYNOTE(objRequestRedirect);
          paynoteRedirect.render();
        };
      }
    // Tokenizing card
    const TokenizingCards = (customerDetail) => {
        setStepUpdate("payment_option")
        // CloseCheckoutModal();
        // Navigate(`${CHECK_OUT_PACKAGE}/${customerDetail?.id}`, {state: customerDetail});
        
    };
    const expiryDates = moment(expiryDate);
    const b = moment().utc();
    const expiryDatesdifrr = expiryDates.diff(b,'days');
    
    // card validations
    const MastercardRegex = /^5[1-5][0-9]{14}$|^2(?:2(?:2[1-9]|[3-9][0-9])|[3-6][0-9][0-9]|7(?:[01][0-9]|20))[0-9]{12}$/;
    const AmericanExpressRegex = /^3[47][0-9]{13}$/;
    const VisacardRegex = /^4[0-9]{12}(?:[0-9]{3})?$/;
    const DiscovercreditcardRegex = /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/;
    const MaestrocreditcardRegex = /^(5018|5081|5044|5020|5038|603845|6304|6759|676[1-3]|6799|6220|504834|504817|504645)[0-9]{8,15}$/;
    const JCBcreditcardRegex = /^(?:2131|1800|35[0-9]{3})[0-9]{11}$/;
    const DinercreditcardRegex = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/;
    const CreditCardValid = MastercardRegex.test(creditCard) === true || AmericanExpressRegex.test(creditCard) === true || VisacardRegex.test(creditCard) === true || DiscovercreditcardRegex.test(creditCard) === true || MaestrocreditcardRegex.test(creditCard) === true || JCBcreditcardRegex.test(creditCard) === true || DinercreditcardRegex.test(creditCard) === true;
    function callAPI(state) {
        const data = fetch('https://api.ipify.org/?format=json')
          .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
              return response.json();
          })
          .then((data) => {
              localStorage.setItem("IP", data?.ip);
              return data?.ip;
          })
          .catch((error) => {
             return ""
          });
          return data
    };

    // payment last step 
    const PaymentLastStep = async () => {
        document.getElementById("checkoutflag")?.classList?.remove("active");
        if(CreditCardValid && (expiryDate !== "" && expiryDatesdifrr > 0) && cvv !== "" && cardholderName !== "") {
            if (expiryDatesdifrr > 0 && cvv?.length === 3){
                const options = Intl.DateTimeFormat().resolvedOptions();
                const timezoneName = options.timeZone;
                document.getElementById("pageisLoading").style.display = "flex";
                setLoader(true);
                setCustomError(false);
                setErrorExpiryDate("");
                const IP = await callAPI();

                const jsonData = JSON.stringify({ 
                    "card_number" : creditCard, 
                    "cardholder_name" : cardholderName, 
                    "order_id" : customerDetail?.id, 
                    "amount" : customerDetail?.total_amount,
                    "ccexp": moment(expiryDate).format('MM/YYYY'),
                    "cvv": cvv,
                    timezone: timezoneName,
                    ipaddress: IP,
                    payment_type: TypePayment === "inoviopay" ? "inoviopay" : "card",
                    coupon_code:rcode.trim()
                });
                window.axios.post(PAYMENT_PLACE_ORDER_API_URL, jsonData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    setTimeout(()=> {
                        document.getElementById("pageisLoading").style.display = "none";
                        dispatch(cardDetailsGet(result.data.data));
                        dispatch(AddMyProduct(selectedProduct));
                        toast.success(result.data.msg);
                        new Audio(sound).play();
                        setSuccessPopup({...SuccessPopup, open: true, title: "Thank you for the payment!"});
                        setCheckoutPoup({...checkoutPoup, open: false});
                        setErrorEmail("");
                        setErrorExpiryDate("");
                        setCreditCardError("");
                        setLoader(false);
                        setPaymentMethodModalState({
                            open: false,
                            title: "",
                        });
                        setTypePayment("");
                    }, 100);
                })  
                .catch(function (result) {
                    dispatch(cardDetailsGet(""));
                    document.getElementById("pageisLoading").style.display = "none";

                    let ErrorMessage = result.response.data.error === undefined ? "" : result.response.data.error;
                    let ErrorMwsg = result.response.data.msg === undefined ? "" : result.response.data.msg;
                    toast.error(ErrorMessage + (ErrorMwsg === "" ? "" : ":") + ErrorMwsg);
                    
                    setLoader(false);
                    if(result?.response?.data?.detail === "Token expired.") {
                        AccessTokenCheckLogout();
                    };
                });
            } else{
                setCustomError(true);
                if(expiryDatesdifrr < 0) {
                    setErrorExpiryDate("Enter Valid Expiry date");
                } else {
                    setErrorExpiryDate("");
                }
            }
        } else {
            setCustomError(true);
            if(expiryDate !== "") {
                if(expiryDatesdifrr < 0 || !expiryDatesdifrr) {
                    setErrorExpiryDate("Enter Valid Expiry date");
                } else {
                    setErrorExpiryDate("");
                }
            } else {
                setErrorExpiryDate("Expiry date cannot be empty");
            }
            if(creditCard === "") {
                setCreditCardError("Credit card number cannot be empty");
            } else if (CreditCardValid === false) {
                setCreditCardError("Enter valid number credit Card");
            } else {
                setCreditCardError("");
            }
        };
    };


    // crypto api calling function
    const cryptoAPICalling = (response) => {
        document.getElementById("checkoutflag")?.classList?.remove("active");
        document.getElementById("pageisLoading").style.display = "flex";
        window.axios.get(`${USER_CRYPTO_PAYMENT_API_URL}/${response?.id}/${parseInt(response?.total_amount / 100)}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            setTimeout(()=> {
                document.getElementById("pageisLoading").style.display = "none";
                dispatch(cardDetailsGet(result.data.data));
                dispatch(AddMyProduct(selectedProduct));

                const approveLink= result?.data?.data?.result?.url
                if (approveLink) {
                    window.location.href = approveLink;
                    fetch(approveLink)
                      .then((response) => {
                        if (response.ok) {
                        } else {
                          console.error("Failed to fetch:", response);
                        }
                      })
                      .catch((error) => {
                        console.error("Error fetching:", error);
                      });
                  }

                // dispatch(OrderIsInProcessModalStateFct({
                //     open: true,
                //     iframe: result?.data?.data?.result?.url,
                // }));
                // setRocketFuelOptions({
                //     ...rocketFuelOptions,
                //     order: result?.data?.data?.result?.returnListingFormat?.details?.invoiceDetails?.itemCarts[0],
                //     rkfl: {},
                //     iframe_url: 'https://iframe.rocketfuelblockchain.com',
                //     fetch_url: "",
                //     data: result?.data?.data,
                // });


                toast.success(result.data.msg);
                // new Audio(sound).play();
                // setSuccessPopup({...SuccessPopup, open: true, title: "Thank you for the payment!"});
                setCheckoutPoup({...checkoutPoup, open: false});
                setErrorEmail("");
                setErrorExpiryDate("");
                setCreditCardError("");
                setLoader(false);
                setPaymentMethodModalState({
                    open: false,
                    title: "",
                });
                setTypePayment("");
            }, 100);
        })  
        .catch(function (result) {
            dispatch(cardDetailsGet(""));
            document.getElementById("pageisLoading").style.display = "none";
            dispatch(OrderIsInProcessModalStateFct(false));
            let ErrorMessage = result.response.data.error === undefined ? "" : result.response.data.error;
            let ErrorMwsg = result.response.data.msg === undefined ? "" : result.response.data.msg;
            toast.error(ErrorMessage + (ErrorMwsg === "" ? "" : ":") + ErrorMwsg);
            
            setLoader(false);
            if(result?.response?.data?.detail === "Token expired.") {
                AccessTokenCheckLogout();
            };
        });
    };

    // onChange effect 
    const OnchangeNoGet = (e) => {
        if(e.target.value.length <= 3) {
            setCVV(e.target.value)
        }
    };

    // close checkout modal
    const CloseCheckoutModal = () => {
        document.getElementById("flagsDropdownid")?.classList?.remove("active");
        document.getElementById("checkoutflag")?.classList?.remove("active");
        setCheckoutPoup({...checkoutPoup, open: false})
    }

    // if accessToken expire then page will logut and redirect to home page 
    const AccessTokenCheckLogout = () => {
        setTimeout(() =>  Navigate(HOME_URL), 200);
        localStorage.removeItem("accessToken");
        dispatch(getAccessToken(""));
        dispatch(checkLogin("no"));
        dispatch(getUser(""));
    };

    // onchange get expiry date and validations
    const onGetExpirydate = (date) => {
        setExpiryDate(date);
        const expiryDates = moment(date);
        const b = moment().utc();
        const expiryDatesdifrr = expiryDates.diff(b,'days');
        if(date !== "") {
            if(expiryDatesdifrr < 0) {
                setErrorExpiryDate("Enter Valid Expiry date");
            } else {
                setErrorExpiryDate("");
            }
        } else {
            setErrorExpiryDate("Expiry date cannot be empty")
        }
    };

    const handleNumericInput = (event) => {
        event.target.value = event.target.value.replace(/[^0-9]/g, '');
    };

    // input field space issue 
    document.getElementById("CardholderNameID")?.addEventListener('keydown', function (e) {
        if (this.value.length === 0 && e.which === 32) e.preventDefault();
    });

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("cvv_fileds")) {
            document.activeElement.blur();
        }
    });
    const callSelectMethod = (type)=>{
        document.getElementById("pageisLoading").style.display = "flex";
        setPaymentMethod(type)
        document.getElementById("pageisLoading").style.display = "none";
    }
    const ArrayPaymentOption = [
        {
            icon:"credit_card",
            type:"card",
            text:"Card",
        },
        // {
        //     icon:"CashApp",
        //     type:"cash_app",
        //     text:"Cash App",
        // },
        // {
        //     icon:"creditIcon",
        //     type:"google_pay",
        //     text:"Other",
        // },
    ];

    const callBackInitPayment = () =>{
        if(paymentMethod == "google_pay" ){
            const TokenNiztion = document.getElementById("iframPayment");
            const iframPaymentBack = document.getElementById("iframPaymentBack");
            TokenNiztion.style.display = "block";
            iframPaymentBack.style.display = "block";
            window.myGlobalFunction();
            // document.getElementById("pageisLoading").style.display = "flex";
            // setTimeout(() => {
            //     window.open('./payment.html', '_blank');
            //     document.getElementById("pageisLoading").style.display = "none";
            // }, 5000);
        }else if(paymentMethod == "cash_app" ){
            
        }else if(paymentMethod == "card" ){
            // const TokenNiztion = document.getElementById("iframPayment");
            // const iframPaymentBack = document.getElementById("iframPaymentBack");
            // TokenNiztion.style.display = "block";
            // iframPaymentBack.style.display = "block";
            // window.myGlobalFunction("google_pay");
            window.myGlobalFunction("google_pay");
        }
    }
    const callPaymentCompleteCashApp = async (data) =>((dispatch, getState)=>{
        const {customerDetail} = getState()?.allReducers
        document.getElementById("pageisLoading").style.display = "flex";
        const paymentDetails = JSON.parse(data);
        const payload = {
            idempotency_key: paymentDetails?.idempotencyKey,
            token: paymentDetails?.sourceId,
            payment_type: "cash-app",
            order_id:"",
            amount:"",
            coupon_code:rcode.trim()
        }
        if(customerDetail?.id){
            payload.order_id = customerDetail?.id;
        }
        if(customerDetail?.id){
            payload.amount = customerDetail?.total_amount;
        }
        const response = window.axios.post(PAYMENT_PLACE_ORDER_API_URL, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            document.getElementById("pageisLoading").style.display = "none";
                audioMedia?.play()?.then(() => {
                    console.log('Audio is playing.');
                }).catch(error => {
                    console.error('Error playing audio:', error);
                });
                dispatch(cardDetailsGet(result.data.data));
                toast(result.data.msg);
                setSuccessPopup({...SuccessPopup, open: true, title: "Thank you for the payment!"});
                dispatch(customerDetailsGet({...result?.data?.data, total_amount: result?.data?.data?.amount}));
                CloseCheckoutModal();
        })  
        .catch(function (result) {
            document.getElementById("pageisLoading").style.display = "none";
            if (result?.response?.status === 401) {
                toast.warning(result.response.data.error);
            } else {
                toast.error(result.response.data.error);
            }        
            // toast.error(result.response.data.error);
            if(result?.response?.data?.detail === "Token expired.") {
                AccessTokenCheckLogout();
            };
            dispatch(customerDetailsGet(result?.data?.data));
        });
    })
    const PaymentAmountDetails = () =>{
        return(
            <div className="paymentheadermain">
                <div>
                    <div className="pymenttitle">
                        {stepUpdate === "yourdetails" ? (
                           <div className="backpymentbtn" onClick={() =>{ Navigate(CHECK_OUT_PACKAGE),setMinumumAmountError("")}}><svg viewBox="0 0 18 18" fill="currentColor" width="18" height="18"><path d="M10.8448202,5.14270801 C11.0394183,5.33600267 11.0404716,5.64963633 10.8476779,5.84372938 L7.71273205,8.99980356 L10.8488003,12.1634729 C11.0414976,12.3578663 11.0408107,12.6714558 10.8472635,12.865003 C10.6532807,13.0582298 10.3404929,13.0576181 10.1479487,12.8643191 L6.29891136,9.00019644 L10.1421589,5.14494052 C10.3357619,4.95073257 10.649987,4.9497342 10.8448202,5.14270801 Z"></path></svg> Back</div>

                        ) : (stepUpdate === "paymentdetailsmethod" || (stepUpdate === "payment_option" && paymentMethod == 'cash_app')) ? (
                            <div className="backpymentbtn" onClick={() => {setStepUpdate("yourdetails"), setPaymentMethod("card"),setMinumumAmountError("")}}><svg viewBox="0 0 18 18" fill="currentColor" width="18" height="18"><path d="M10.8448202,5.14270801 C11.0394183,5.33600267 11.0404716,5.64963633 10.8476779,5.84372938 L7.71273205,8.99980356 L10.8488003,12.1634729 C11.0414976,12.3578663 11.0408107,12.6714558 10.8472635,12.865003 C10.6532807,13.0582298 10.3404929,13.0576181 10.1479487,12.8643191 L6.29891136,9.00019644 L10.1421589,5.14494052 C10.3357619,4.95073257 10.649987,4.9497342 10.8448202,5.14270801 Z"></path></svg> Back</div>
                        ) : (
                            <div className="backpymentbtn" onClick={() =>{ setStepUpdate("paymentdetailsmethod"),setMinumumAmountError("")}}><svg viewBox="0 0 18 18" fill="currentColor" width="18" height="18"><path d="M10.8448202,5.14270801 C11.0394183,5.33600267 11.0404716,5.64963633 10.8476779,5.84372938 L7.71273205,8.99980356 L10.8488003,12.1634729 C11.0414976,12.3578663 11.0408107,12.6714558 10.8472635,12.865003 C10.6532807,13.0582298 10.3404929,13.0576181 10.1479487,12.8643191 L6.29891136,9.00019644 L10.1421589,5.14494052 C10.3357619,4.95073257 10.649987,4.9497342 10.8448202,5.14270801 Z"></path></svg> Back</div>
                        )}
                        <div className="dropdowncustome">
                            <div className="dropdowntoggle" onClick={() => setPaymentDropdown(!paymentDropdown)}>
                                <h6 className="w-auto d-inline mb-0 pe-1">Package - {selectedProduct?.name}</h6> <span className="w-auto d-inline mb-0"> (${Number(selectedProduct.price).toLocaleString()})</span>
                                {/* <svg viewBox="0 0 18 18" fill="currentColor" width="18" height="18"><path d="M3.96966991,5.96966991 C4.23593648,5.70340335 4.65260016,5.6791973 4.94621165,5.89705176 L5.03033009,5.96966991 L9.5,10.439 L13.9696699,5.96966991 C14.2359365,5.70340335 14.6526002,5.6791973 14.9462117,5.89705176 L15.0303301,5.96966991 C15.2965966,6.23593648 15.3208027,6.65260016 15.1029482,6.94621165 L15.0303301,7.03033009 L9.5,12.5606602 L3.96966991,7.03033009 C3.6767767,6.73743687 3.6767767,6.26256313 3.96966991,5.96966991 Z"></path></svg> */}
                            </div>    
                            {/* {paymentDropdown && (<div className="amountdetailsdropdown">
                                <div className="posterdetails">
                                    <span>{"Single Package"}</span>
                                    <span>Quantity: 1</span>
                                </div>
                                <div>${selectedProduct.price}</div>
                            </div>)} */}
                        </div>
                    </div>
                </div>
                <div className="paymentssteps">
                    <div className="active"></div>
                    <div className={(stepUpdate === "paymentdetailsmethod" || stepUpdate === "payment_option") ? "active" : ""}></div>
                    <div className={stepUpdate === "paymentdetails" || (stepUpdate === "payment_option" && paymentMethod == 'card') ? "active" : ""}></div>
                </div>
            </div>
        )
    }

    const CardOldModel = () =>{
        return(
            <div className="paymentforms paymentFormsWrapper">
                <div className="rowcustom">
                    <div className="col-md-12">
                        <div className={creditCardError !== "" && CreditCardValid !== true ? "form-group error" : "form-group"}>
                            <label>{TypePayment==="debit_card" ?"Debit":"Credit"} Card Number</label>
                            <div className="form-groupfiled">
                                <input type="tel" className="form-control" value={cc_format(creditCard)} onChange={(e) => creditcardNo(e)} placeholder="Enter Credit Card Number" maxLength="23" pattern="\d*" />
                                {CreditCardValid === true && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                {CreditCardValid !== true && <div className="danger-color">{creditCardError}</div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rowcustom rowcustom-col-2">
                    <div className={errorExpiryDate !== "" ? "form-group error" : "form-group"}>
                        <label>Expiry Date</label>
                        <div className="form-groupfiled expirydatswrapper">
                            <input type="text" className="d-none"  />
                            <DatePicker
                                selected={expiryDate}
                                onChange={(date) => onGetExpirydate(date)}
                                dateFormat="MM/yyyy"
                                placeholderText="Select Expiry Date"
                                autoComplete="off"
                                showMonthYearPicker
                            />
                            {errorExpiryDate !== "" ? <img src={ErrorIcon} className="errorsuccessicon" alt={"icon"} /> : 
                            (errorExpiryDate === "" && expiryDate !== "") ? <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} /> : <React.Fragment></React.Fragment>}
                            {errorExpiryDate !== "" && <div className="danger-color">{errorExpiryDate}</div>}
                            {/* {(expiryDatesdifrr < 0) && <div className="danger-color">{errorExpiryDate}</div>} */}
                        </div>
                    </div>
                    <div className={CustomError && cvv === "" ? "form-group error" : "form-group"}>
                        <label>CVV</label>
                        <div className="form-groupfiled cvv-groupfiled">
                            <input type="password" className="d-none"  />
                            <input type={showHideCvv ? "number" : "password"} value={cvv} onChange={(e) => OnchangeNoGet(e)} className="form-control cvv_fileds" onInput={handleNumericInput} placeholder="Enter CVV" maxLength="3" autoComplete="off" />
                            <span className="input-group-text" onClick={() => setShowHideCvv(!showHideCvv)}>
                                {showHideCvv ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg> : <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>}
                            </span>
                            {cvv?.length === 3 && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            {CustomError && cvv?.length !== 3 && <CustomMendotoryMsg value={cvv} label={"CVV"} />}
                        </div>
                    </div>
                </div>
                <div className="rowcustom">
                    <div className="col-md-12">
                        <div className={CustomError && cardholderName === "" ? "form-group error" : "form-group"}>
                            <label>Cardholder Name</label>
                            <div className="form-groupfiled">
                                <input type="text" className="form-control" id="CardholderNameID" value={cardholderName} onChange={(e) => {
                                const inputValue = e.target.value;
                                const regex = /^[A-Za-z\s]*$/; // Regular expression to match alphabet characters and spaces

                                if (regex.test(inputValue)) {
                                    setCardholderName(inputValue);
                                }
                                }} placeholder="Enter Cardholder Name" 
                                />
                                {cardholderName !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                {CustomError && cardholderName === "" && <CustomMendotoryMsg value={cardholderName} label={"Cardholder Name"} />}
                            </div>
                        </div>
                    </div>

                </div>
                <div className="rowcustom">
                        <div className="col-md-12">
                            <div className="form-group">
                            <label>Coupon Code</label>
                            <div className="form-groupfiled">
                            <input type="text" className="form-control" 
                                value={rcode} 
                                onChange={(e) => setRCode(e.target.value.trim())}
                                placeholder="Enter Coupon Code" autoComplete="off"/>
                                {rcode !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                            </div>
                            </div>
                        </div>
                        </div>
            </div>
        )
    }
    if(stepUpdate === "payment_option" && paymentMethod === "card") {
        return(
            <div className={`${props?.modalState ? "CheckoutModalWrapper": "CheckoutModalWrapper1"}`}>
                <button className="closeModal" onClick={() => CloseCheckoutModal()}>
                    <svg viewBox="0 0 6 6" fill="currentColor" width="12px" height="12px"><path d="M5.2 0L3 2.2 0.8 0 0 0.8 2.2 3 0 5.3 0.8 6 3 3.8 5.2 6 6 5.3 3.8 3 6 0.8z"></path></svg>
                </button>

                {!props?.paymentState ?(
                    <React.Fragment>
                        {/* <h4 className="mb-3">2. Payment Method</h4>
                        <p>Select payment option</p> */}
                        {PaymentAmountDetails()}
                        <h4 className="mb-3">{stepUpdate === "yourdetails" ? "1. Your Details" : stepUpdate === "paymentdetailsmethod" ?  "2. Select Payment Method" : TypePayment === "cash_app"?"Payment": "3. Payment Details"}</h4>
                    </React.Fragment>
                ):(
                    <React.Fragment>
                        <div className="d-inline-flex flex-column w-auto">
                            <h4 className="mb-3">Payment Method</h4>
                            <p>Select payment option</p>
                        </div>
                        <div className="dropdowncustome">
                        <div className="dropdowntoggle" onClick={() => setPaymentDropdown(!paymentDropdown)}>
                            ${Number(selectedProduct.price).toLocaleString()}
                            <svg viewBox="0 0 18 18" fill="currentColor" width="18" height="18"><path d="M3.96966991,5.96966991 C4.23593648,5.70340335 4.65260016,5.6791973 4.94621165,5.89705176 L5.03033009,5.96966991 L9.5,10.439 L13.9696699,5.96966991 C14.2359365,5.70340335 14.6526002,5.6791973 14.9462117,5.89705176 L15.0303301,5.96966991 C15.2965966,6.23593648 15.3208027,6.65260016 15.1029482,6.94621165 L15.0303301,7.03033009 L9.5,12.5606602 L3.96966991,7.03033009 C3.6767767,6.73743687 3.6767767,6.26256313 3.96966991,5.96966991 Z"></path></svg>
                        </div>    
                        {paymentDropdown && (<div className="amountdetailsdropdown">
                            <div className="posterdetails">
                                <span>{"Single Package"}</span>
                                <span>Quantity: 1</span>
                            </div>
                            <div>${Number(selectedProduct.price).toLocaleString()}</div>
                        </div>)}
                    </div>
                    </React.Fragment>
                )}
                {/* <div className="paymentforms min"> */}
                    <div className="payment-method">
                        {/* <div className="payment-content mb-4 pt-3">
                            <div className="row justify-content-center">
                                {ArrayPaymentOption?.map((item, index)=>(
                                    <div className="col-12 col-sm-4 mb-3" onClick={()=>callSelectMethod(item?.type)} key={index}>
                                        <div className={`payment-item ${item?.type == paymentMethod?"active":""}`}>
                                            <i className={item?.icon} />
                                            <span>{item?.text}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div> */}
                        {paymentMethod == "card" && (
                            <React.Fragment>
                                 {CardOldModel()}
                                <Button className="btn formcomnbtn" disabled={Loader} onClick={() => PaymentLastStep()}>Pay Now</Button>
                            </React.Fragment>
                        )}
                        {paymentMethod == "cash_app" && (
                            <React.Fragment>
                                <CashPayComponent {...props} SecureImage={SecureImage} PaymentContinueStepGeo={PaymentContinueStepGeo} callBackInitPayment={callPaymentCompleteCashApp} amount={selectedProduct.price} />
                            </React.Fragment>
                        )}
                        {/* {paymentMethod == "google_pay" && (
                            <React.Fragment>
                                <button className="btn clickpaymnt btn btn-primary " onClick={callBackInitPayment} >Submit</button>
                            </React.Fragment>
                        )} */}
                        <img src={SecureImage} className="secureimage" alt="SecureImage" />
                    </div>
                {/* </div> */}

                {/* {PaymentMethodModalState?.open && (<PaymentMethedModal 
                    PaymentMethodModalState={PaymentMethodModalState} 
                    setPaymentMethodModalState={setPaymentMethodModalState}
                />)} */}
            </div>
        )
    }

    return(<div  className={`${props?.modalState ? "CheckoutModalWrapper": "CheckoutModalWrapper1 height-100"} `}>
            <div>
                
            <button className="closeModal" onClick={() => CloseCheckoutModal()}>
                <svg viewBox="0 0 6 6" fill="currentColor" width="12px" height="12px"><path d="M5.2 0L3 2.2 0.8 0 0 0.8 2.2 3 0 5.3 0.8 6 3 3.8 5.2 6 6 5.3 3.8 3 6 0.8z"></path></svg>
            </button>
            {/* {stepUpdate === "yourdetails" && (<div className="backpymentbtn" onClick={() =>{ Navigate(CHECK_OUT_PACKAGE),setMinumumAmountError("")}}><svg viewBox="0 0 18 18" fill="currentColor" width="18" height="18"><path d="M10.8448202,5.14270801 C11.0394183,5.33600267 11.0404716,5.64963633 10.8476779,5.84372938 L7.71273205,8.99980356 L10.8488003,12.1634729 C11.0414976,12.3578663 11.0408107,12.6714558 10.8472635,12.865003 C10.6532807,13.0582298 10.3404929,13.0576181 10.1479487,12.8643191 L6.29891136,9.00019644 L10.1421589,5.14494052 C10.3357619,4.95073257 10.649987,4.9497342 10.8448202,5.14270801 Z"></path></svg> Back</div>)} */}
            {PaymentAmountDetails()}
            </div>
            <h4>{stepUpdate === "yourdetails" ? "1. Your Details" : (stepUpdate === "paymentdetailsmethod" || (stepUpdate === "payment_option" && paymentMethod == 'cash_app')) ?  "2. Select Payment Method" : "3. Payment Details"}</h4>
            <div className="paymentWrappers">
                {stepUpdate === "yourdetails" ? (<div className="paymentforms height-100">
                        <div className="rowcustom rowcustom-col-2">
                            <div className={CustomError && fname === "" ? "form-group error" : "form-group"}>
                                <label>First Name</label>
                                <div className="form-groupfiled">
                                    <input type="text"
                                    autoFocus="true"
                                     className="form-control" value={fname} onChange={(e) => setFname(e.target.value.trim())} placeholder="Enter First Name" />
                                    {fname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                    {CustomError && fname === "" && <CustomMendotoryMsg value={fname} label={"First name"} />}
                                </div>
                            </div>
                            <div className={CustomError && lname === "" ? "form-group error" : "form-group"}>
                                <label>Last Name</label>
                                <div className="form-groupfiled">
                                    <input type="text"
                                    autoFocus="true"
                                     className="form-control" value={lname} onChange={(e) => setLname(e.target.value.trim())} placeholder="Enter Last Name" 
                                    />
                                    {lname !== "" && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                    {CustomError && lname === "" && <CustomMendotoryMsg value={lname} label={"Last name"} />}
                                </div>
                            </div>
                        </div>
                        <div className="rowcustom rowcustom-col-2">
                            <div className={(errorEmail !== "" && EmailRegex.test(email) === false) ? "form-group error" : "form-group"}>
                                <label>Email</label>
                                <div className="form-groupfiled">
                                    <input  type="email" className="form-control" value={email} 
                                    autoComplete="email"
                                    autoFocus="true"
                                    onChange={(e) => setEmail(e.target.value.trim())} placeholder="Enter email" />
                                    {EmailRegex.test(email) === true && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                    {EmailRegex.test(email) === false && <div className="danger-color">{errorEmail}</div>}
                                </div>
                            </div>
                            <div className={CustomError && SelectCountry === "" ? "form-group error" : "form-group"}>
                                <label>Country</label>
                                <div className="form-groupfiled">
                                    <select className="form-control" onChange={(e) => SelectCoutry(e)} defaultValue={SelectCountry} aria-label="Default select example">
                                        <option value="">Select Country</option>
                                        {Country.getAllCountries().map((elm, index) => {
                                            return(<option value={elm.name} key={index}>{elm.name}</option>)
                                        })}
                                    </select>
                                    {CustomError && SelectCountry === "" && <CustomMendotoryMsg value={SelectCountry} label={"Country"} />}
                                </div>
                            </div>
                        </div>
                        <div className="rowcustom">
                            <div className="col-md-12">
                                <div className={(MobileError !== "" && MobileNo?.number?.toString()?.length !== 10) ? "form-group error" : "form-group"}>
                                    <label>Phone</label>
                                    <div className="form-groupfiled">
                                        <CountrySelectInput 
                                            MobileNo={MobileNo}
                                            setMobileNo={setMobileNo}
                                            id={"checkoutflag"}
                                        />
                                        {MobileNo?.number?.toString()?.length === 10 && <img src={SucessIcon} className="errorsuccessicon" alt={"icon"} />}
                                        {MobileNo?.number?.toString()?.length !== 10 && <div className="danger-color">{MobileError}</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                      
                </div>) : (stepUpdate === "paymentdetailsmethod" || (stepUpdate === "payment_option" && paymentMethod == 'cash_app')) ? (<PaymentMethedModal 
                    PaymentMethodModalState={PaymentMethodModalState} 
                    setPaymentMethodModalState={setPaymentMethodModalState}
                    SubmitPaymentMethod={SubmitPaymentMethod}
                    setTypePayment={setTypePayment}
                    callSelectMethod={callSelectMethod}
                    setStepUpdate={setStepUpdate}
                    minimunamounterror={minimunamounterror}
                    PaymentContinueStepGeo={PaymentContinueStepGeo}
                    callPaymentCompleteCashApp={callPaymentCompleteCashApp}
                    selectedProduct={selectedProduct}
                    paymentMethod={paymentMethod}
                    props={props}
                    TypePayment={TypePayment}
                    GetGeoLicenceFunction={GetGeoLicenceFunction}
                />) : (<div className="modal_payments">
                    <CardOldModel />
                </div>)}
                {stepUpdate !== "paymentdetailsmethod" && !(stepUpdate === "payment_option" && paymentMethod == 'cash_app') && (<div className="rowcustom">
                    <div className="col-md-12 mb-2">
                        {stepUpdate === "yourdetails" ? (
                            <Button className="btn formcomnbtn right-Icon" onClick={() => PaymentSelectMethod()}>Continue <img src={RightArrowIcon} /></Button>
                        ) :
                        (<Button className="btn formcomnbtn" disabled={Loader} onClick={() => PaymentLastStep()}>Pay Now</Button>)}
                        {/* <div className="securepymentnote">
                            <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24"><path d="M9,9 L16,9 C17.1045695,9 18,9.8954305 18,11 L18,16 C18,17.6568542 16.6568542,19 15,19 L10,19 C8.34314575,19 7,17.6568542 7,16 L7,11 C7,9.8954305 7.8954305,9 9,9 Z M9,10 C8.44771525,10 8,10.4477153 8,11 L8,16 C8,17.1045695 8.8954305,18 10,18 L15,18 C16.1045695,18 17,17.1045695 17,16 L17,11 C17,10.4477153 16.5522847,10 16,10 L9,10 Z M12.5,5 C14.4329966,5 16,6.56700338 16,8.5 L16,9 L9,9 L9,8.5 C9,6.56700338 10.5670034,5 12.5,5 Z M12.5,6 C11.1192881,6 10,7.11928813 10,8.5 L10,9 L15,9 L15,8.5 C15,7.11928813 13.8807119,6 12.5,6 Z M13,13 L14,13 L14,14 L13,14 L13,16 L12,16 L12,14 L11,14 L11,13 L12,13 L12,12 L13,12 L13,13 Z"></path></svg>
                            SSL Secure Payment
                        </div> */}
                    </div>
                    <img src={SecureImage} className="secureimage" alt="SecureImage" />
                </div>)}
            </div>

            {(OrderIsInPrcessModalState?.open !== undefined && OrderIsInPrcessModalState?.open) && (<OrderInProcessModal DontWorryModal={DontWorryModal} setDontWorryModal={setDontWorryModal} />)}
            {(OrderIsInPrcessModalState?.open !== undefined && OrderIsInPrcessModalState?.open) && (<div className="ModalBackground"></div>)}

            {DontWorryModal?.open && (<OrderProcessCancelModal setDontWorryModal={setDontWorryModal} />)}
            {DontWorryModal?.open && (<div className="ModalBackground" style={{ zIndex: "9999" }}></div>)}
            {/* <RocketFuelPayment 
                rocketFuelOptions={rocketFuelOptions} 
                setRocketFuelOptions={setRocketFuelOptions} 
            /> */}
    </div>)
}
CheckoutModal.propTypes = {
    modalState: PropTypes.bool
}
CheckoutModal.defaultProps = {
    modalState: true
}
export default CheckoutModal;