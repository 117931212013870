import React from "react";

const PromotionalRules = () => {
    return(<section className="privacypage">
        <div className="main-header">
            <div className="container pravcypolicycontainer">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Promotional Rules</h1>
                    </div>
                </div>
            </div>
        </div>
        <div className="comnpages">
            <div className="container pravcypolicycontainer">
                <div className="row">
                    <div className="col-md-12">
                        <p style={{ textAlign: "center" }}>
                        Sweeps Coins Promotion of Bundles of Books & Motivate on Pape<br /><br />
                        Official Rules<br /><br />
                        NO PURCHASE NECESSARY TO ENTER OR WIN.<br />
                        A PURCHASE OR PAYMENT WILL NOT IMPROVE YOUR CHANCES OF WINNING.
                        </p>
                        <br /><br />
                        <p>Eligibility.  This Sweeps Coins Promotion (“Promotion”) is open to all natural persons who are at least the age of majority in their jurisdiction at time of entry, and who are members of/hold accounts with Sweeps Coins.  Void outside the USA.  Void in the States of Washington and Idaho.  The Promotion is subject to all applicable federal, state, and local laws and regulations and is void where prohibited by law.
                        <br /><br />
 

                        Sponsorship.  The Promotion sponsor is Sweeps Coins (“Sponsor”), located at PO Box 128, Ada, MI 49301. Sponsor and its respective affiliates, subsidiaries, successors, assigns, agents, representatives, officers, directors, shareholders, and employees, and any entity involved in the development, production, implementation, administration, judging or fulfillment of the Promotion, including without limitation, the immediate family members of such individuals, are not eligible to participate.  Sponsor will conduct the Promotion substantially as described in these Official Rules.
                        <br /><br />
                        

                        Entry Period.  This Promotion begins on [MONTH 1, YEAR] at 12:00 AM EST and ends on [NEXT MONTH 1, YEAR] at 12:00 AM EST (“Entry Period”).
                        <br /><br />
                        

                        How to Enter.  During the Entry Period, members may enter by purchasing products from Bundles of Books and/or Motivate on Pape.  The products will come with free sweepstakes entries, which can be recorded through Sweeps Coins’ website, sweepscoins.games.  Alternatively, members may obtain entry for free by mailing a completed order form to PO Box 128, Ada, MI 49301.  Order forms can be downloaded and printed from Sweeps Coins’ website, sweepscoins.games.  For any members who do not have the ability to download and print order forms, those members can call 1-888-926-4679 and request a form be mailed to them.  Entries not received or used before the end of the entry period may be rolled over into any subsequent promotion run by this Sponsor.
                        <br /><br />
                        

                        Limitations on Entries.  No third-party entry or entry through any outside promotion service is permitted.  Any person who attempts or otherwise encourages the entry through false contact information or under multiple identities or uses any device or artifice to enter or encourage false entries, as determined by Sponsor, will be disqualified.  Use of robotic entry devices is strictly prohibited.  Free entry mail-in forms are limited to one form per person per day.  If multiple mail-in forms are received on the same day, only one form will be honored.  Sponsor reserves the right to disqualify individuals for repeated abuse of the free entry process.
                        <br /><br />
                        

                        Prizes.  Prizes will vary depending upon the games played by entrants (as multiple different gaming options are available to the entrants of this promotional sweepstakes).  Sponsor may limit cash prizes to $10,000 at its discretion, and cash prizes in New York and Florida are limited to $5,000.
                        <br /><br />
                        

                        Odds.  Odds will vary depending upon the games played by entrants (as multiple different gaming options are available to the entrants of this promotional sweepstakes).  
                        <br /><br />
                        

                        WARNING:  ANY ATTEMPT BY ANY PERSON, WHETHER OR NOT AN ENTRANT, TO DELIBERATELY DAMAGE, DESTROY, TAMPER WITH OR VANDALIZE ANY SPONSOR-OWNED WEBSITE OR MOBILE APPLICATION OR RELATED SOCIAL NETWORKING SITE, THE ENTRY PROCESS, OR OTHERWISE INTERFERE WITH OR UNDERMINE THE LEGITIMATE OPERATION OF THE PROMOTION, MAY BE A VIOLATION OF CRIMINAL AND CIVIL LAWS AND SPONSOR RESERVES THE RIGHT TO SEEK DAMAGES AND DILIGENTLY PURSUE ALL REMEDIES AGAINST ANY SUCH PERSON TO THE FULLEST EXTENT PERMITTED BY LAW.
                        <br /><br />
                        

                        General Terms.  By entering this Promotion, you promise to abide by the Official Rules and decisions of Sponsor, which will be final and binding in all respects.  Sponsor reserves the right, at its sole discretion, to refuse, disqualify or withdraw any entry at any time.  Sponsor will not be responsible for any injury, damage or loss of any kind arising out of your participation in the Promotion.

                        <br /><br />

                        YOU AGREE TO RELEASE, DISCHARGE, AND HOLD HARMLESS SPONSOR, ITS AFFILIATES, SUBSIDIARIES, EMPLOYEES, OFFICERS, DIRECTORS, AGENTS AND ASSIGNS FROM AND AGAINST ANY OR ALL CLAIMS, LOSSES, INJURIES, OR DAMAGES RESULTING FROM PARTICIPATION IN THE PROMOTION. 
                        <br /><br />
                        

                        Except where prohibited by law, by accepting prizes, winners grant Sponsor a perpetual, worldwide, royalty license to use winners’ names, photographs, voices, and/or likenesses without further authorization, compensation, or remuneration of any kind for advertising, promotion and other publicity purposes in any and all media now or hereafter known throughout the world.

                        
                        <br /><br />
                        General Rules of Play.  Prizes will be assigned to winners based upon their participation and performance in various styles of entertainment/social games of their choosing.  Players may choose not to play or withdraw from play at any time.  Sponsor’s decisions and interpretations on all matters relating to the Promotion and these Official Rules are final and binding in all respects.  Sponsor reserves the right to disqualify any entrant or winner and may refuse to award a prize to a person who is not eligible or has violated a rule, gained unfair advantage in the Promotion, or obtained winner status using fraudulent means.  If there is a dispute about the identity of an entrant, prize(s) will be awarded to the natural person to whom the email address is assigned by the internet service provider responsible for the domain associated with the submitted email address.  If a winner is found to be ineligible or not in compliance with Promotion rules, the winner will be disqualified.  Winners are solely responsible for any federal, state, and local taxes. Winners must provide Sponsor with a valid Social Security number or taxpayer identification number before prizes will be awarded.  For prizes valued over $600, a Form 1099 will be issued to winners.  For prizes valued over $5,000, Sponsor is required by law to withhold 25% of the prize value for federal income tax purposes.

                        <br /><br />

                        Winner’s Name. The name of winners is available only within fifteen (15) days after the close of the Promotion.  To receive the name of the winners, where permitted by law, or a printed copy of the Promotion Official Rules, please contact Sponsor by calling 1-888-926-4679.

                        <br /><br />

                        Disputes/Venue/Governing Law.  By participating, entrants agree that (1) any and all disputes, claims and causes of action arising out of, or connected with, this Promotion or any prize awarded (if applicable) shall be resolved individually, without resort to any form of class action, and exclusively by the appropriate court located in in the State of Michigan; (2) any and all claims, judgments and awards shall be limited to actual out-of-pocket costs incurred, including but not limited to costs associated with entering this Promotion but in no event attorneys’ fees; and (3) to the extent allowed by applicable law, under no circumstances will entrant be permitted to obtain awards for, and entrant hereby waives all rights to claim, punitive, incidental and/or consequential damages and/or any other damages, other than out-of-pocket expenses, and any and all rights to have damages multiplied or otherwise increased.  

                        <br /><br />

                        All issues and questions concerning the construction, validity, interpretation and enforceability of these Official Rules or the rights and obligations of any participant or Sponsor in connection with the Promotion shall be governed by and construed in accordance with the internal laws of the State of Michigan without giving effect to any choice of law or conflict of law rules or provisions that would cause the application of any other state's laws.

                        <br /><br />

                        The invalidity or unenforceability of any provision of these Official Rules shall not affect the validity or enforceability of any other provision.  In the event that any provision is determined to be invalid or otherwise unenforceable or illegal, these Official Rules shall otherwise remain in effect and shall be construed in accordance with their terms as if the invalid or illegal provision were not contained herein.  In the event there is a discrepancy or inconsistency between disclosures or other statements contained in any Promotion-related materials or notifications and the terms and conditions of these Official Rules, these Official Rules shall prevail, govern and control.

                        <br /><br />

                        Terms & Privacy.  Participation in this Promotion is subject to the Sweeps Coins Terms & Conditions located at sweepscoins.games.  By entering this Promotion, you agree to adhere to the terms, policies and guidelines described in the above Terms & Conditions.  The personal information collected through this Promotion is subject to Sweeps Coins Privacy Policy located at sweepscoins.games.  By entering this Promotion, you agree to the use of your personal information as described in the above Privacy Policy.  In the event of any conflict between these Official Rules and Sweeps Coins Terms & Conditions and/or Privacy Policy, these Official Rules shall take precedence.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>)
}

export default PromotionalRules;